import React, { Component } from 'react';
import './Collection.css';
import '@shopify/polaris/dist/styles.css';
import { RangeSlider, Select, Page, Card, TextStyle, TextField, FormLayout, DisplayText, Button, Checkbox, Banner, Layout, Spinner, Stack } from '@shopify/polaris';
import { Loading } from '@shopify/app-bridge-react';
import { postFetch, putFetch, getFetch } from '../helpers/request';
import { Redirect } from 'react-router';
import AutocompleteTags from './AutocompleteTags';
import TagsInput from './TagsInput';
import collectionImage from '../images/advcol1.svg';
import selectionListImage from '../images/advcol2.svg';

interface CollectionProps {
  type: string,
  shopOrigin: string,
  id?: string
}

interface CollectionState {
  smartCollectionOptions: Array<any>,
  availableTags: any,
  includeSelectedTags: any,
  excludeSelectedTags: any,
  filterSelectedTags: any,
  typeOptions:any,
  includeTypes: any,
  excludeTypes: any,
  filterTypes: any,
  vendorOptions: any,
  includeVendors: any,
  excludeVendors: any,
  filterVendors: any,
  hasLoaded: boolean,
  errorLoading: boolean,
  collectionTitle: string,
  isSaving: boolean,
  autoSync: boolean,
  collectionTitleTouched: boolean,
  redirectToListing: boolean,
  showBanner:boolean,
  selectedSmartCollectionIds: Array<any>,
  excludedSmartCollectionIds: Array<any>,
  includeStartsWithTags: any[],
  includeEndsWithTags: any[],
  includeContainsTags: any[],
  excludeStartsWithTags: any[],
  excludeEndsWithTags: any[],
  excludeContainsTags: any[],
  includePriceLowerThanComparedAt: boolean,
  includeDiscountType:string,
  excludePriceLowerThanComparedAt: boolean,
  excludeDiscountType:string,
  filterPriceLowerThanComparedAt: boolean,
  filterDiscountType:string,
  includeAllProducts: boolean,
  includeNewnessType: string,
  includeNewnessDays: string,
  excludeNewnessType: string,
  excludeNewnessDays: string,
  includeDiscountPercentageRange: any,
  excludeDiscountPercentageRange: any,
  filterDiscountPercentageRange: any,
  includeInventoryType: string,
  includeInventoryQuantity: string,
  excludeInventoryType: string,
  excludeInventoryQuantity: string,
  excludeInventoryIgnoreContinue: boolean,
  excludeInventoryIgnoreUntracked: boolean,
}

class Collection extends Component <CollectionProps, CollectionState> {

  constructor(props: CollectionProps) {
    super(props);
    this.state = { 
      isSaving: false,
      hasLoaded: false,
      errorLoading: false,
      collectionTitle: '',
      autoSync: true,
      collectionTitleTouched: false,
      redirectToListing: false,
      availableTags: [],
      typeOptions: [],
      includeTypes: [],
      excludeTypes: [],
      filterTypes: [],
      vendorOptions: [],
      includeVendors: [],
      excludeVendors: [],
      filterVendors: [],
      includeSelectedTags: [],
      excludeSelectedTags: [],
      filterSelectedTags: [],
      smartCollectionOptions: [],
      showBanner: true,
      selectedSmartCollectionIds: [],
      excludedSmartCollectionIds: [],
      includeStartsWithTags: [],
      includeEndsWithTags: [],
      includeContainsTags: [],
      excludeStartsWithTags: [],
      excludeEndsWithTags: [],
      excludeContainsTags: [],
      includePriceLowerThanComparedAt: false,
      includeDiscountType: 'none',
      excludePriceLowerThanComparedAt: false,
      excludeDiscountType: 'none',
      filterPriceLowerThanComparedAt: false,
      filterDiscountType: 'none',
      includeAllProducts: false,
      includeNewnessType: 'none',
      includeNewnessDays: '30',
      excludeNewnessType: 'none',
      excludeNewnessDays: '30',
      includeDiscountPercentageRange: [0, 100],
      excludeDiscountPercentageRange: [0, 100],
      filterDiscountPercentageRange: [0, 100],
      includeInventoryType: 'none',
      includeInventoryQuantity: '0',
      excludeInventoryType: 'none',
      excludeInventoryQuantity: '0',
      excludeInventoryIgnoreContinue: false,
      excludeInventoryIgnoreUntracked: false,
    };
  }
  

  componentDidMount() {
    if (this.props.type === 'new') {
      this.loadTags();
    } else if (this.props.type === 'existing') {
      getFetch('/app/api/collections/?id='+this.props.id, {})
        .then((res:any) => res.json()
        ).then(data => {
          this.setState({collectionTitle: data.collection_title});
          this.setState({includeSelectedTags: data.include_tags});
          this.setState({excludeSelectedTags: data.exclude_tags});
          this.setState({filterSelectedTags: data.filter_tags})
          this.setState({selectedSmartCollectionIds: data.associated_smart_collections});
          this.setState({excludedSmartCollectionIds: data.excluded_smart_collections});
          this.setState({autoSync: data.auto_sync});
          
          if (data.include_vendors) {
            this.setState({includeVendors: data.include_vendors.split('/&/')});
          }

          if (data.exclude_vendors) {
            this.setState({excludeVendors: data.exclude_vendors.split('/&/')});
          }

          if (data.filter_vendors) {
            this.setState({filterVendors: data.filter_vendors.split('/&/')});
          }

          if (data.include_types) {
            this.setState({includeTypes: data.include_types.split('/&/')});
          }

          if (data.exclude_types) {
            this.setState({excludeTypes: data.exclude_types.split('/&/')});
          }

          if (data.filter_types) {
            this.setState({filterTypes: data.filter_types.split('/&/')});
          }

          if (data.include_tags_starts_with) {
            this.setState({includeStartsWithTags: data.include_tags_starts_with.split(',')});
          }

          if (data.include_tags_ends_with) {
            this.setState({includeEndsWithTags: data.include_tags_ends_with.split(',')});
          }
          
          if (data.include_tags_contains) {
            this.setState({includeContainsTags: data.include_tags_contains.split(',')});
          }
          if (data.exclude_tags_starts_with) {
            this.setState({excludeStartsWithTags: data.exclude_tags_starts_with.split(',')});
          }
          if (data.exclude_tags_ends_with) {
            this.setState({excludeEndsWithTags: data.exclude_tags_ends_with.split(',')});
          }
          if (data.exclude_tags_contains) {
            this.setState({excludeContainsTags: data.exclude_tags_contains.split(',')});
          }

          this.setState({includePriceLowerThanComparedAt: data.include_price_less_than_compare_at});
          this.setState({includeDiscountType: data.include_discount_type});
          this.setState({includeDiscountPercentageRange: [data.include_discount_min ? data.include_discount_min : 0, 
            data.include_discount_max ? data.include_discount_max : 100]});

          this.setState({excludePriceLowerThanComparedAt: data.exclude_price_less_than_compare_at});
          this.setState({excludeDiscountType: data.exclude_discount_type});
          this.setState({excludeDiscountPercentageRange: [data.exclude_discount_min ? data.exclude_discount_min : 0, 
            data.exclude_discount_max ? data.exclude_discount_max : 100]});
          
          this.setState({filterPriceLowerThanComparedAt: data.filter_price_less_than_compare_at});
          this.setState({filterDiscountType: data.filter_discount_type});
          this.setState({filterDiscountPercentageRange: [data.filter_discount_min ? data.filter_discount_min : 0, 
            data.filter_discount_max ? data.filter_discount_max : 100]});

          this.setState({includeNewnessType: data.include_newness_type});
          this.setState({includeNewnessDays: '' + data.include_newness_days});
          this.setState({excludeNewnessType: data.exclude_newness_type});
          this.setState({excludeNewnessDays: '' + data.exclude_newness_days});

          this.setState({includeInventoryType: data.include_inventory_type});
          this.setState({includeInventoryQuantity: '' + data.include_inventory_quantity});
          this.setState({excludeInventoryType: data.exclude_inventory_type});
          this.setState({excludeInventoryQuantity: '' + data.exclude_inventory_quantity});
          this.setState({excludeInventoryIgnoreContinue: data.exclude_inventory_ignore_continue});
          this.setState({excludeInventoryIgnoreUntracked: data.exclude_inventory_ignore_untracked});

          this.setState({includeAllProducts: data.include_all_products});

          this.loadTags();
        });
    }
  }

  loadTags = () => {
    let tagsKey = 'adv-col/' + this.props.shopOrigin;
    let supportsLocalStorage = true;
    let data = null;
    try {
      data = localStorage.getItem(tagsKey);
    } catch (e) {
      supportsLocalStorage = false;
    }
    if (data === null || !supportsLocalStorage) {
      getFetch('/app/api/shopify/products-tags', {})
        .then((res:any) => res.json()
        ).then(data => {
          if (supportsLocalStorage) {
            localStorage.setItem(tagsKey, JSON.stringify(data));
          }
          this.initTags(data);
        }).catch(err => {
          this.setState({ hasLoaded: true });
          this.setState({ errorLoading: true });
          console.log(err);
      });
    } else {
      this.initTags(JSON.parse(localStorage.getItem(tagsKey)!));
    }
  }

  refreshPage = () => {
    let supportsLocalStorage = true;
    try {
      localStorage.getItem('test');
    } catch (e) {
      supportsLocalStorage = false;
    }
    if (supportsLocalStorage) {
      let tagsKey = 'adv-col/' + this.props.shopOrigin;
      localStorage.removeItem(tagsKey);
    }
    window.location.reload(false);
  }

  save = () => {
    this.setState({isSaving:true});
    
    let body:any = {
      collection_title: this.state.collectionTitle,
      include_tags: this.state.includeSelectedTags,
      exclude_tags: this.state.excludeSelectedTags,
      filter_tags: this.state.filterSelectedTags,
      include_tags_starts_with: this.state.includeStartsWithTags.join(','),
      include_tags_ends_with: this.state.includeEndsWithTags.join(','),
      include_tags_contains: this.state.includeContainsTags.join(','),
      exclude_tags_starts_with: this.state.excludeStartsWithTags.join(','),
      exclude_tags_ends_with: this.state.excludeEndsWithTags.join(','),
      exclude_tags_contains: this.state.excludeContainsTags.join(','),
      include_price_less_than_compare_at: this.state.includePriceLowerThanComparedAt,
      include_discount_type: this.state.includeDiscountType,
      include_discount_min: this.state.includeDiscountPercentageRange[0],
      include_discount_max: this.state.includeDiscountPercentageRange[1],
      exclude_price_less_than_compare_at: this.state.excludePriceLowerThanComparedAt,
      exclude_discount_type: this.state.excludeDiscountType,
      exclude_discount_min: this.state.excludeDiscountPercentageRange[0],
      exclude_discount_max: this.state.excludeDiscountPercentageRange[1],
      filter_price_less_than_compare_at: this.state.filterPriceLowerThanComparedAt,
      filter_discount_type: this.state.filterDiscountType,
      filter_discount_min: this.state.filterDiscountPercentageRange[0],
      filter_discount_max: this.state.filterDiscountPercentageRange[1],
      include_all_products: this.state.includeAllProducts,
      include_newness_type: this.state.includeNewnessType,
      include_newness_days: this.state.includeNewnessDays,
      exclude_newness_type: this.state.excludeNewnessType,
      exclude_newness_days: this.state.excludeNewnessDays,
      include_inventory_type: this.state.includeInventoryType,
      include_inventory_quantity: this.state.includeInventoryQuantity,
      exclude_inventory_type: this.state.excludeInventoryType,
      exclude_inventory_quantity: this.state.excludeInventoryQuantity,
      exclude_inventory_ignore_continue: this.state.excludeInventoryIgnoreContinue,
      exclude_inventory_ignore_untracked: this.state.excludeInventoryIgnoreUntracked,
      auto_sync: this.state.autoSync,
      associated_smart_collections: this.state.selectedSmartCollectionIds,
      excluded_smart_collections: this.state.excludedSmartCollectionIds,
      include_types:this.state.includeTypes.join('/&/'),
      exclude_types:this.state.excludeTypes.join('/&/'),
      filter_types:this.state.filterTypes.join('/&/'),
      include_vendors: this.state.includeVendors.join('/&/'),
      exclude_vendors: this.state.excludeVendors.join('/&/'),
      filter_vendors: this.state.filterVendors.join('/&/'),
    };

    if (this.props.type === 'new') {
      postFetch('/app/api/collections/create/', body).then(
        (res) => { 
          this.setState({redirectToListing: true});
        }
      ).catch( 
        (err) => console.log(err)
      );
    } else if (this.props.type === 'existing') {
      body.id = this.props.id;
      putFetch('/app/api/collections/update/', body).then(
        (res) => { 
          this.setState({redirectToListing: true});
        }
      ).catch( 
        (err) => console.log(err)
      );
    }
  }
  
  handleTitleChange = (value:string) => {
    this.setState({collectionTitleTouched: true});
    this.setState({collectionTitle: value});
  };

  handleIncludeStartsWithTagsChange = (includeStartsWithTags: Array<string>) => {
    this.setState({includeStartsWithTags: includeStartsWithTags});
  }

  handleIncludeEndsWithTagsChange = (includeEndsWithTags: Array<string>) => {
    this.setState({includeEndsWithTags: includeEndsWithTags});
  } 

  handleIncludeContainsTagsChange = (includeContainsTags: Array<string>) => {
    this.setState({includeContainsTags: includeContainsTags});
  }

  handleExcludeStartsWithTagsChange = (excludeStartsWithTags: Array<string>) => {
    this.setState({excludeStartsWithTags: excludeStartsWithTags});
  }

  handleExcludeEndsWithTagsChange = (excludeEndsWithTags: Array<string>) => {
    this.setState({excludeEndsWithTags: excludeEndsWithTags});
  } 

  handleExcludeContainsTagsChange = (excludeContainsTags: Array<string>) => {
    this.setState({excludeContainsTags: excludeContainsTags});
  } 

  handleIncludePriceLowerThanComparedAtChange = (value:boolean) => {
    this.setState({includePriceLowerThanComparedAt: value});
  };

  handleIncludeDiscountTypeChange =(value:string) => {
    this.setState({includeDiscountType: value});
  }

  handleIncludeDiscountPercentageRangeChange = (value:any) => {
    this.setState({includeDiscountPercentageRange: value});
  }

  handleExcludeDiscountTypeChange =(value:string) => {
    this.setState({excludeDiscountType: value});
  }

  handleExcludePriceLowerThanComparedAtChange = (value:boolean) => {
    this.setState({excludePriceLowerThanComparedAt: value});
  };

  handleExcludeDiscountPercentageRangeChange = (value:any) => {
    this.setState({excludeDiscountPercentageRange: value});
  }

  handleFilterDiscountTypeChange =(value:string) => {
    this.setState({filterDiscountType: value});
  }

  handleFilterPriceLowerThanComparedAtChange = (value:boolean) => {
    this.setState({filterPriceLowerThanComparedAt: value});
  };

  handleFilterDiscountPercentageRangeChange = (value:any) => {
    this.setState({filterDiscountPercentageRange: value});
  }

  handleIncludeAllProducts = (value:boolean) => {
    this.setState({includeAllProducts: value});
  };

  handleAutoSyncChange = (value:boolean) => {
    this.setState({autoSync: value});
  };

  includeTagsChange = (selectedTags: Array<string>) => {
    this.setState({includeSelectedTags:selectedTags});
  }

  handleIncludeNewnessTypeChange = (value: string) => {
    this.setState({includeNewnessType: value});
  }
  
  handleIncludeNewnessDaysChange = (value: string) => {
    this.setState({includeNewnessDays: value});
  }

  handleExcludeNewnessTypeChange = (value: string) => {
    this.setState({excludeNewnessType: value});
  }

  handleExcludeNewnessDaysChange = (value: string) => {
    this.setState({excludeNewnessDays: value});
  }

  // vendors
  handleIncludeVendorsChange = (selectedVendors: Array<string>) => {
    this.setState({includeVendors: selectedVendors});
  }

  handleExcludeVendorsChange = (selectedVendors: Array<string>) => {
    this.setState({excludeVendors: selectedVendors});
  }

  handleFilterVendorsChange = (selectedVendors: Array<string>) => {
    this.setState({filterVendors: selectedVendors});
  }

  // types
  handleIncludeTypesChange = (selectedTypes: Array<string>) => {
    this.setState({includeTypes: selectedTypes});
  }

  handleExcludeTypesChange = (selectedTypes: Array<string>) => {
    this.setState({excludeTypes: selectedTypes});
  }

  handleFilterTypesChange = (selectedTypes: Array<string>) => {
    this.setState({filterTypes: selectedTypes});
  }

  // inventory
  handleIncludeInventoryTypeChange = (value: string) => {
    this.setState({includeInventoryType: value});
  }
  
  handleIncludeInventoryQuantityChange = (value: string) => {
    this.setState({includeInventoryQuantity: value});
  }

  handleExcludeInventoryTypeChange = (value: string) => {
    this.setState({excludeInventoryType: value});
  }

  handleExcludeInventoryQuantityChange = (value: string) => {
    this.setState({excludeInventoryQuantity: value});
  }

  handleExcludeInventoryIgnoreContinueChange = (value: boolean) => {
    this.setState({excludeInventoryIgnoreContinue: value});
  }

  handleExcludeInventoryIgnoreUntrackedChange = (value: boolean) => {
    this.setState({excludeInventoryIgnoreUntracked: value});
  }

  // tags
  excludeTagsChange = (selectedTags: Array<string>) => {
    this.setState({excludeSelectedTags:selectedTags});
  }

  filterTagsChange = (selectedTags: Array<string>) => {
    this.setState({filterSelectedTags:selectedTags});
  }

  selectedSmartCollectionChange = (selectedIds: Array<number>) => {
    this.setState({selectedSmartCollectionIds: selectedIds});
  }

  excludedSmartCollectionChange = (selectedIds: Array<number>) => {
    this.setState({excludedSmartCollectionIds: selectedIds});
  }

  convertStringArrayToValueLabelObjectArray(ss: Array<string>) {
    let oa = [];
    for (let s of ss) {
      oa.push({
        value: s, label: s
      });
    }
    return oa;
  }

  initTags(data:any) {
    let availableTags = data.tags;
    availableTags.sort();
    let availableVendors = data.vendors;
    availableVendors.sort();
    let availableTypes = data.types;
    availableTypes.sort();
    this.setState({ typeOptions: this.convertStringArrayToValueLabelObjectArray(availableTypes) });
    this.setState({ vendorOptions: this.convertStringArrayToValueLabelObjectArray(availableVendors) });
    this.setState({ availableTags: this.convertStringArrayToValueLabelObjectArray(availableTags) });
    // updates available tags list
    this.includeTagsChange(this.state.includeSelectedTags);
    this.excludeTagsChange(this.state.excludeSelectedTags);
    

    let smartCollectionOptions = [];
    let smartCollections = data.smart_collections;
    for (let smartCollection of smartCollections) {
      smartCollectionOptions.push(
        {
          label: smartCollection.title,
          value: smartCollection.id
        }
      );
    }
    this.setState({ smartCollectionOptions: smartCollectionOptions});
    this.setState({ hasLoaded: true });
  }
  

  render() {
    let inventoryTypeOptions = [
      {'label': 'No', 'value': 'none'},
      {'label': 'Total quantity equals to', 'value': 'total-equals'},
      {'label': 'Total quantity less than', 'value': 'total-less_than'},
      {'label': 'Total quantity more than', 'value': 'total-more_than'},
      {'label': 'Any variant quantity equals to', 'value': 'any-equals'},
      {'label': 'Any variant quantity less than', 'value': 'any-less_than'},
      {'label': 'Any variant quantity more than', 'value': 'any-more_than'},
      {'label': 'All variant quantity equals to', 'value': 'all-equals'},
      {'label': 'All variant quantity less than', 'value': 'all-less_than'},
      {'label': 'All variant quantity more than', 'value': 'all-more_than'},
      {'label': 'Main product / first variant quantity equals to', 'value': 'first-equals'},
      {'label': 'Main product / first variant quantity less than', 'value': 'first-less_than'},
      {'label': 'Main product / first variant quantity more than', 'value': 'first-more_than'},
    ];
    let newnessTypeOptions = [
      {'label': 'No', 'value': 'none'},
      {'label': 'Within days from Updated Date', 'value': 'updated_at-within'},
      {'label': 'Within days from Published Date', 'value': 'published_at-within'},
      {'label': 'Within days from Created Date', 'value': 'created_at-within'},
      {'label': 'More than days from Updated Date', 'value': 'updated_at-more_than'},
      {'label': 'More than days from Published Date', 'value': 'published_at-more_than'},
      {'label': 'More than days from Created Date', 'value': 'created_at-more_than'},
    ];
    let discountTypeOptions = [
      {'label': "As long as any variant price is lower than its compared at price", 'value': 'none'},
      {'label': "Only if all variants have their prices lower than their compared at prices", 'value': 'all_variants'},
      {'label': 'If discount percentage is within range', 'value': 'percentage'}
    ]
    if (this.state.redirectToListing === true) {
      return <Redirect to='/collection-listing?tabindex=0' />
    }
    let saveAndSyncDisabled = this.state.collectionTitle.length === 0 || 
      (this.state.selectedSmartCollectionIds.length===0 && !this.state.includeAllProducts &&
      this.state.includeContainsTags.length===0 && this.state.includeStartsWithTags.length===0 && this.state.includeEndsWithTags.length===0 && this.state.includeTypes.length===0 && this.state.includeVendors.length===0 &&
      !this.state.includePriceLowerThanComparedAt && this.state.includeSelectedTags.length===0 && this.state.includeNewnessType === 'none' && this.state.includeInventoryType === 'none') ||
      (this.state.includeNewnessType !== 'none' && (this.state.includeNewnessDays === '' || this.state.includeNewnessDays === '0')) ||
      (this.state.excludeNewnessType !== 'none' && (this.state.excludeNewnessDays === '' || this.state.excludeNewnessDays === '0') ||
      (this.state.includeInventoryType !== 'none' && this.state.includeInventoryQuantity === '' ) ||
      (this.state.excludeInventoryType !== 'none' && this.state.excludeInventoryQuantity === '' )
    );
    return (
        <Page
          breadcrumbs={[{content: 'Manage Collections', onAction:()=>this.setState({redirectToListing: true})}]}>
          {!this.state.hasLoaded && (<Loading />)}
          <DisplayText size="large">{this.props.type === 'new' ? "Create New Advanced Collection" : "Edit Collection"}</DisplayText>
          {this.state.showBanner && this.props.type === 'existing' && (<Banner
            title="Modifying an advanced collection"
            status="info"
            onDismiss={() => {this.setState({showBanner: false})}}>
            <p>After you save the modified collection, the app will save the changed collection title on Shopify. If auto-sync is enabled, the app will start syncing your products according to your newly chosen conditions.</p>
          </Banner>)}
          {this.state.showBanner && this.props.type === 'new' && (<Banner
            title="How this works"
            status="info"
            onDismiss={() => {this.setState({showBanner: false})}}>
            <p>After you create an advanced collection, the app will create a manual collection with the same title on Shopify and start syncing your products to this collection according to the conditions you have chosen.</p>
          </Banner>)}
          {!this.state.hasLoaded && (
            <div className="loader">
              <Layout><Spinner size="large" /></Layout>
              <Layout><DisplayText size="small">Loading tags / vendors / product types / automated collections selection lists... This might take a few minutes if you have many collections and products. Thank you for your patience.</DisplayText></Layout>
            </div>
          )}          
          {this.state.hasLoaded && (
            <div>
              <Card sectioned title="Advanced collection options">
              <div className="indent-section">
                <FormLayout>
                <TextField
                  label="Enter collection title (required)"
                  value={this.state.collectionTitle}
                  onChange={this.handleTitleChange}
                  error={this.state.collectionTitleTouched && this.state.collectionTitle.length==0 ? "Collection name is required" : 
                         //!collectionTitleRegex.test(this.state.collectionTitle) ? "Collection name contains illegal characters" :
                         false}
                />
                <Checkbox
                  checked={this.state.autoSync}
                  label="Auto Sync"
                  onChange={this.handleAutoSyncChange}
                />
                <p>If auto syncing is enabled, the advanced collection will sync automatically every time you update or create a product which matches the conditions you have chosen.</p>
                </FormLayout>
                </div>
              </Card>
              <Layout>
                <Layout.Section oneHalf>
                <Card sectioned>
                  <FormLayout>
                    <div className="text-center"><img className="illustrate" src={collectionImage} /></div>
                    <p className="text-center"><strong>How to Choose Conditions</strong></p>
                    <p className="text-center">There are many conditions you can choose from to create advanced collections. While the list of options is long, do not fret! You probably just need a few, and they are easy to understand. To give an overview on how this works:</p>
                    <p className="text-center">
                      1. Products which satisfy <strong>any of the include conditions</strong> are first added.
                    </p>
                    <p className="text-center">
                      2. Next, products which satisfy <strong>any of the exclude conditions</strong> are removed. 
                    </p>
                    <p className="text-center">
                      3. Finally, only products<strong> which match all of the filter conditions</strong> remains on the list.
                    </p>
                  </FormLayout>
                </Card>
                </Layout.Section>
                <Layout.Section oneHalf>
                <Card sectioned>
                  <FormLayout>
                    <div className="text-center"><img className="illustrate" src={selectionListImage} /></div>
                    <p className="text-center"><strong>Refresh Selection Lists</strong></p>
                    <p className="text-center">Click on the button below to refresh your selection list of Tags, Vendors, Product Type and Automated Collections if you are unable to find them in the search fields below.</p>
                    <p className="text-center"><Button onClick={this.refreshPage}>Refresh Selection Lists</Button></p>
                    <p className="text-center subdued">In order to speed up loading time, your store’s selection lists are cached.</p>
                    <p className="text-center subdued">If your store has a lot of collections and products, the refreshing time may take a few minutes.</p>
                  </FormLayout>
                </Card>
                </Layout.Section>
              </Layout>
              <Card sectioned title="Include products">
              <p style={{paddingBottom:'20px'}}>Choose the conditions by which products will be included in your advanced collection. Products matching <strong>ANY</strong> of these conditions will be included. At least 1 condition is needed here.</p>
              <div className="indent-section">
                <FormLayout>
                <TextStyle variation="strong">Include all products?</TextStyle>
                <Checkbox
                  checked={this.state.includeAllProducts}
                  label="Include all products"
                  onChange={this.handleIncludeAllProducts}
                />
                
                {!this.state.includeAllProducts && (
                  <FormLayout>
                    <hr className="minor-divider" style={{marginTop:'30px'}} />
                    <TextStyle variation="strong">Tags includes</TextStyle>
                    <AutocompleteTags requiresTypingToFilter={true} errorLoading={this.state.errorLoading} helpText="A list to select will appear after you type 2 or more letters. Type more to filter more if it is hard to select / deselect" listTitle='Tags to include' selected={this.state.includeSelectedTags} onChange={this.includeTagsChange} options={this.state.availableTags} placeholderText='Type to start searching'>Include product if one of its tags <strong>EQUALS</strong> to <strong>ANY</strong> of the following:</AutocompleteTags>
                    <TagsInput selected={this.state.includeStartsWithTags} onChange={this.handleIncludeStartsWithTagsChange} placeholderText='Type and press enter'>Include product if one of its tags <strong>STARTS</strong> with <strong>ANY</strong> of the following:</TagsInput>
                    <TagsInput selected={this.state.includeEndsWithTags} onChange={this.handleIncludeEndsWithTagsChange} placeholderText='Type and press enter'>Include product if one of its tags <strong>ENDS</strong> with <strong>ANY</strong> of the following:</TagsInput>
                    <TagsInput selected={this.state.includeContainsTags} onChange={this.handleIncludeContainsTagsChange} placeholderText='Type and press enter'>Include product if one of its tags <strong>CONTAINS</strong> <strong>ANY</strong> of the following:</TagsInput>
                    <hr className="minor-divider" />
                    <TextStyle variation="strong">Price includes</TextStyle>
                    <Checkbox
                      checked={this.state.includePriceLowerThanComparedAt}
                      label="Include products if 'Price' is lower than 'Compared At Price'"
                      onChange={this.handleIncludePriceLowerThanComparedAtChange}
                    />
                    {this.state.includePriceLowerThanComparedAt && (
                      <Select
                        label="Criteria"
                        options={discountTypeOptions}
                        onChange={this.handleIncludeDiscountTypeChange}
                        value={this.state.includeDiscountType}
                      />
                    )}
                    {this.state.includePriceLowerThanComparedAt && this.state.includeDiscountType === 'percentage' &&(
                      <RangeSlider
                        output
                        label="Include products if discount percentage is within the following range"
                        value={this.state.includeDiscountPercentageRange}
                        suffix='%'
                        min={0}
                        max={100}
                        step={1}
                        onChange={this.handleIncludeDiscountPercentageRangeChange}
                      />
                    )}
                    <hr className="minor-divider" />
                    <TextStyle variation="strong">Date includes</TextStyle>
                    <Select
                      label="Include products based on date:"
                      options={newnessTypeOptions}
                      onChange={this.handleIncludeNewnessTypeChange}
                      value={this.state.includeNewnessType}
                    />
                    {this.state.includeNewnessType !== 'none' && (
                      <TextField
                        label="Number of days"
                        type="number"
                        value={this.state.includeNewnessDays}
                        onChange={this.handleIncludeNewnessDaysChange}
                        error={(this.state.includeNewnessDays === '' || this.state.includeNewnessDays === '0') && this.state.includeNewnessType !== 'none'}
                      />
                    )}
                    <hr className="minor-divider" />
                    <TextStyle variation="strong">Inventory includes</TextStyle>
                    <Select
                      label="Include products based on inventory quantities:"
                      options={inventoryTypeOptions}
                      onChange={this.handleIncludeInventoryTypeChange}
                      value={this.state.includeInventoryType}
                    />
                    {this.state.includeInventoryType !== 'none' && (
                      <TextField
                        label="Quantity"
                        type="number"
                        value={this.state.includeInventoryQuantity}
                        onChange={this.handleIncludeInventoryQuantityChange}
                        error={this.state.includeInventoryQuantity === '' && this.state.includeInventoryType !== 'none'}
                      />
                    )}
                    <hr className="minor-divider" />
                    <TextStyle variation="strong">Collections includes</TextStyle>
                    <AutocompleteTags requiresTypingToFilter={true} errorLoading={this.state.errorLoading} helpText="A list to select will appear after you type 2 or more letters. Type more to filter more if it is hard to select / deselect" listTitle='Automated collection includes' selected={this.state.selectedSmartCollectionIds} onChange={this.selectedSmartCollectionChange} options={this.state.smartCollectionOptions} placeholderText='Type to start searching'>Include products from the following automated collections:</AutocompleteTags>
                    <hr className="minor-divider" />
                    <TextStyle variation="strong">Vendor includes</TextStyle>
                    <AutocompleteTags requiresTypingToFilter={true} errorLoading={this.state.errorLoading} helpText="A list to select will appear after you type 2 or more letters. Type more to filter more if it is hard to select / deselect" listTitle='Vendors to include' selected={this.state.includeVendors} onChange={this.handleIncludeVendorsChange} options={this.state.vendorOptions} placeholderText='Type to start searching'>Include product if one of its vendors <strong>EQUALS</strong> to <strong>ANY</strong> of the following:</AutocompleteTags>
                    <hr className="minor-divider" />
                    <TextStyle variation="strong">Product Type includes</TextStyle>
                    <AutocompleteTags requiresTypingToFilter={true} errorLoading={this.state.errorLoading} helpText="A list to select will appear after you type 2 or more letters. Type more to filter more if it is hard to select / deselect" listTitle='Product Types to include' selected={this.state.includeTypes} onChange={this.handleIncludeTypesChange} options={this.state.typeOptions} placeholderText='Type to start searching'>Include product if one of its product types <strong>EQUALS</strong> to <strong>ANY</strong> of the following:</AutocompleteTags>
                  </FormLayout>
                )}
                </FormLayout>
              </div>
              </Card>

              <Card sectioned title="Exclude products">
                <p style={{paddingBottom:'20px'}}>Choose the conditions by which products will be excluded. Products matching <strong>ANY</strong> of these conditions will be removed from the above.</p>
                <div className="indent-section">
                <FormLayout>
                  <TextStyle variation="strong">Tags excludes</TextStyle>
                  <AutocompleteTags requiresTypingToFilter={true} errorLoading={this.state.errorLoading} helpText="A list to select will appear after you type 2 or more letters. Type more to filter more if it is hard to select / deselect" listTitle='Tags to exclude' selected={this.state.excludeSelectedTags} onChange={this.excludeTagsChange} options={this.state.availableTags} placeholderText='Type to start searching'>Exclude product if one of its tags <strong>EQUALS</strong> to <strong>ANY</strong> of the following:</AutocompleteTags>
                  <TagsInput selected={this.state.excludeStartsWithTags} onChange={this.handleExcludeStartsWithTagsChange} placeholderText='Type and press enter'>Exclude product if one of its tags <strong>STARTS</strong> with <strong>ANY</strong> of the following:</TagsInput>
                  <TagsInput selected={this.state.excludeEndsWithTags} onChange={this.handleExcludeEndsWithTagsChange} placeholderText='Type and press enter'>Exclude product if one of its tags <strong>ENDS</strong> with <strong>ANY</strong> of the following:</TagsInput>
                  <TagsInput selected={this.state.excludeContainsTags} onChange={this.handleExcludeContainsTagsChange} placeholderText='Type and press enter'>Exclude product if one of its tags <strong>CONTAINS</strong> <strong>ANY</strong> of the following:</TagsInput>
                  <hr className="minor-divider" />
                  <TextStyle variation="strong">Price excludes</TextStyle>
                  <Checkbox
                    checked={this.state.excludePriceLowerThanComparedAt}
                    label="Exclude products if 'Price' is lower than 'Compared at Price'"
                    onChange={this.handleExcludePriceLowerThanComparedAtChange}
                  />
                  {this.state.excludePriceLowerThanComparedAt && (
                    <Select
                        label="Criteria"
                        options={discountTypeOptions}
                        onChange={this.handleExcludeDiscountTypeChange}
                        value={this.state.excludeDiscountType}
                      />
                  )}
                  {this.state.excludePriceLowerThanComparedAt && this.state.excludeDiscountType === 'percentage' &&(
                      <RangeSlider
                        output
                        label="Exclude products if discount percentage is within the following range"
                        value={this.state.excludeDiscountPercentageRange}
                        suffix='%'
                        min={0}
                        max={100}
                        step={1}
                        onChange={this.handleExcludeDiscountPercentageRangeChange}
                      />
                  )}
                  <hr className="minor-divider" />
                  <TextStyle variation="strong">Date excludes</TextStyle>
                  <Select
                      label="Exclude products based on date:"
                      options={newnessTypeOptions}
                      onChange={this.handleExcludeNewnessTypeChange}
                      value={this.state.excludeNewnessType}
                    />
                    {this.state.excludeNewnessType !== 'none' && (
                      <TextField
                        label="Number of days"
                        type="number"
                        value={this.state.excludeNewnessDays}
                        onChange={this.handleExcludeNewnessDaysChange}
                        error={(this.state.excludeNewnessDays === '' || this.state.excludeNewnessDays === '0') && this.state.excludeNewnessType !== 'none'}
                      />
                    )}
                    <hr className="minor-divider" />
                    <TextStyle variation="strong">Inventory excludes</TextStyle>
                    <Select
                        label="Exclude products based on inventory quantities:"
                        options={inventoryTypeOptions}
                        onChange={this.handleExcludeInventoryTypeChange}
                        value={this.state.excludeInventoryType}
                    />
                    {this.state.excludeInventoryType !== 'none' && (
                      <div>
                        <TextField
                          label="Quantity"
                          type="number"
                          value={this.state.excludeInventoryQuantity}
                          onChange={this.handleExcludeInventoryQuantityChange}
                          error={this.state.excludeInventoryQuantity === '' && this.state.excludeInventoryType !== 'none'}
                        />
                        <Checkbox
                          label="Do not exclude product if any of its variants has policy to continue selling even when out of stock"
                          checked={this.state.excludeInventoryIgnoreContinue}
                          onChange={this.handleExcludeInventoryIgnoreContinueChange}
                        />
                        <Checkbox
                          label="Do not exclude product if any of its variants' inventory is untracked"
                          checked={this.state.excludeInventoryIgnoreUntracked}
                          onChange={this.handleExcludeInventoryIgnoreUntrackedChange}
                        />
                      </div>
                    )}

                    <hr className="minor-divider" />
                    <TextStyle variation="strong">Collections excludes</TextStyle>
                    <AutocompleteTags requiresTypingToFilter={true} errorLoading={this.state.errorLoading} helpText="A list to select will appear after you type 2 or more letters. Type more to filter more if it is hard to select / deselect" listTitle='Automated collection excludes' selected={this.state.excludedSmartCollectionIds} onChange={this.excludedSmartCollectionChange} options={this.state.smartCollectionOptions} placeholderText='Type to start searching'>Exclude products from the following automated collections:</AutocompleteTags>

                    <hr className="minor-divider" />
                    <TextStyle variation="strong">Vendor excludes</TextStyle>
                    <AutocompleteTags requiresTypingToFilter={true} errorLoading={this.state.errorLoading} helpText="A list to select will appear after you type 2 or more letters. Type more to filter more if it is hard to select / deselect" listTitle='Vendors to include' selected={this.state.excludeVendors} onChange={this.handleExcludeVendorsChange} options={this.state.vendorOptions} placeholderText='Type to start searching'>Exclude product if one of its vendors <strong>EQUALS</strong> to <strong>ANY</strong> of the following:</AutocompleteTags>
                    <hr className="minor-divider" />
                    <TextStyle variation="strong">Product Type excludes</TextStyle>
                    <AutocompleteTags requiresTypingToFilter={true} errorLoading={this.state.errorLoading} helpText="A list to select will appear after you type 2 or more letters. Type more to filter more if it is hard to select / deselect" listTitle='Product Types to exclude' selected={this.state.excludeTypes} onChange={this.handleExcludeTypesChange} options={this.state.typeOptions} placeholderText='Type to start searching'>Exclude product if one of its product types <strong>EQUALS</strong> to <strong>ANY</strong> of the following:</AutocompleteTags>
                </FormLayout>
                </div>
              </Card>

              <Card sectioned title="Filter products">
                <p style={{paddingBottom:'20px'}}>Choose the conditions by which the products above will be filtered. Only products which matches <strong>ALL</strong> of the following conditions will remain. Filter conditions are not required.</p>
                <div className="indent-section">
                  <FormLayout>
                    <TextStyle variation="strong">Tag filter</TextStyle>
                    <AutocompleteTags requiresTypingToFilter={true} errorLoading={this.state.errorLoading} helpText="A list to select will appear after you type 2 or more letters. Type more to filter more if it is hard to select / deselect" listTitle='Tags to filter' selected={this.state.filterSelectedTags} onChange={this.filterTagsChange} options={this.state.availableTags} placeholderText='Type to start searching'>Include product only if its tags <strong>MATCHES ALL</strong> of the following:</AutocompleteTags>
                    <hr className="minor-divider" />
                    <TextStyle variation="strong">Price filter</TextStyle>
                    <Checkbox
                    checked={this.state.filterPriceLowerThanComparedAt}
                    label="Include products only if 'Price' is lower than 'Compared at Price'"
                    onChange={this.handleFilterPriceLowerThanComparedAtChange}
                  />
                  {this.state.filterPriceLowerThanComparedAt && (
                    <Select
                        label="Criteria"
                        options={discountTypeOptions}
                        onChange={this.handleFilterDiscountTypeChange}
                        value={this.state.filterDiscountType}
                      />
                  )}
                  {this.state.filterPriceLowerThanComparedAt && this.state.filterDiscountType === 'percentage' &&(
                      <RangeSlider
                        output
                        label="Include products only if discount percentage is within the following range"
                        value={this.state.filterDiscountPercentageRange}
                        suffix='%'
                        min={0}
                        max={100}
                        step={1}
                        onChange={this.handleFilterDiscountPercentageRangeChange}
                      />
                  )}
                   <hr className="minor-divider" />
                  <TextStyle variation="strong">Vendor filter</TextStyle>
                  <AutocompleteTags requiresTypingToFilter={true} errorLoading={this.state.errorLoading} helpText="A list to select will appear after you type 2 or more letters. Type more to filter more if it is hard to select / deselect" listTitle='Vendors to filter' selected={this.state.filterVendors} onChange={this.handleFilterVendorsChange} options={this.state.vendorOptions} placeholderText='Type to start searching'>Include products only if its vendor <strong>EQUALS</strong> to <strong>ANY</strong> of the following:</AutocompleteTags>
                  <hr className="minor-divider" />
                  <TextStyle variation="strong">Product Type filter</TextStyle>
                  <AutocompleteTags requiresTypingToFilter={true} errorLoading={this.state.errorLoading} helpText="A list to select will appear after you type 2 or more letters. Type more to filter more if it is hard to select / deselect" listTitle='Product Types to filter' selected={this.state.filterTypes} onChange={this.handleFilterTypesChange} options={this.state.typeOptions} placeholderText='Type to start searching'>Include products only if its product type <strong>EQUALS</strong> to <strong>ANY</strong> of the following:</AutocompleteTags>
                  </FormLayout>
                </div>
                </Card>
                <Card sectioned>
                  <Stack distribution="trailing">
                  <Button onClick ={()=>this.setState({redirectToListing: true})} disabled={this.state.isSaving}>Cancel</Button>
                  <Button primary onClick={this.save} loading={this.state.isSaving} disabled={saveAndSyncDisabled}>{this.props.type==='new' || this.state.autoSync ? 'Save & Sync' : 'Save without syncing'}</Button>
                  </Stack>
                </Card>
              </div>
          )}
        </Page>
    );
  }
}

export default Collection;
