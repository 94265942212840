import React, { Component } from 'react';
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import './Sort.css';
import '@shopify/polaris/dist/styles.css';
import { Button, Page, Card, Select, FormLayout, DisplayText, TextStyle, Banner, Layout, Spinner, Checkbox } from '@shopify/polaris';
import { Loading } from '@shopify/app-bridge-react';
import { getFetch, postFetch, putFetch } from '../helpers/request';
import { Redirect } from 'react-router';
import AutocompleteTags from './AutocompleteTags'
import sortImage from '../images/advcol3.svg';
import selectionListImage from '../images/advcol2.svg';


interface SortProps {
  type: string,
  shopOrigin: string,
  id?: string
}

interface SortState {
  availableTags: any,
  targetTypeOptions: Array<any>,
  collectionOptions: Array<any>,
  excludeCollectionOptions: Array<any>,
  excludedCollectionIds:  Array<any>,
  selectedCollection: string,
  sortCriteriaOptions: Array<any>,
  selectedSortCriteria: string,
  sortOrderOptions: Array<any>,
  locationTargetOptions: Array<any>,
  selectedLocationIds: Array<any>,
  selectedSortOrder: string,
  selectedTargetType: string,
  hasLoaded: boolean,
  errorLoading: boolean,
  isSaving: boolean,
  redirectToListing: boolean,
  showBanner:boolean,
  pushOOSToBack: boolean,
  pushOOSIgnoreContinue: boolean,
  pushOOSIgnoreUntracked: boolean,
  selectedLocationTarget: string,
  locationOptions: Array<any>,
  pushFrontTags: any,
  pushBackTags: any
}

class Sort extends Component <SortProps, SortState> {

  constructor(props: SortProps) {
    super(props);
    this.state = { 
      pushFrontTags: [],
      pushBackTags: [],
      availableTags: [],
      collectionOptions: [],
      excludeCollectionOptions: [],
      excludedCollectionIds: [],
      selectedLocationIds: [],
      locationOptions: [],
      selectedCollection: '',
      selectedTargetType: 'single',
      targetTypeOptions: [
        {
          label: 'Single Collection',
          value: 'single',
          key: 'single_collection'
        },
        {
          label: 'All Collections',
          value: 'all',
          key: 'all_collections',
        }
      ],
      locationTargetOptions: [
        {
          label: 'All Inventory Locations',
          value: 'all',
          key: 'all',
        }, {
          label: 'Selected Inventory Locations',
          value: 'selected_locations',
          key: 'selected_locations'
        }
      ],
      sortCriteriaOptions: [{
        label: 'Publish Date',
        value: 'published_at',
        key: 'published_at',
      }, 
      {
        label: 'Created Date',
        value: 'created_at',
        key: 'created_at',
      },{
        label: 'Updated Date',
        value: 'updated_at',
        key: 'updated_at'
      }, 
      {
        label: 'Alphabetically',
        value: 'alphabetical',
        key: 'alphabetical',
      },
      {
        label: 'Price',
        value: 'price',
        key: 'price',
      },
      {
        label: 'Total Inventory',
        value: 'total_inventory',
        key: 'total_inventory',
      },
      {
        label: 'Discount Percentage',
        value: 'discount_percentage',
        key: 'discount_percentage',
      },
      {
        label: 'Discount Amount',
        value: 'discount_amount',
        key: 'discount_amount',
      },
      {
        label: 'Manually',
        value: 'manual',
        key: 'manual',
      },
      {
        label: 'Randomly',
        value: 'random',
        key: 'random',
      }],
      selectedSortCriteria: 'published_at',
      sortOrderOptions: [{
        label: 'Descending (Latest to Oldest)',
        value: 'descending',
        key: 'descending'
      }, {
        label: 'Ascending (Oldest to Latest)',
        value: 'ascending',
        key: 'ascending'
      }],
      selectedSortOrder: 'descending',
      isSaving: false,
      hasLoaded: false,
      errorLoading: false,
      redirectToListing: false,
      showBanner: true,
      pushOOSToBack: false,
      pushOOSIgnoreContinue: false,
      pushOOSIgnoreUntracked: false,
      selectedLocationTarget: 'all'
    };
  }
  

  componentDidMount() {
    if (this.props.type === 'new') {
      getFetch('/app/api/shopify/all-collections', {})
      .then((res:any) => res.json()
      ).then(data => {
        this.setState({ locationOptions: this.initLocationOptions(data.locations)});
        let collectionsData = this.prepareCollectionsData(data.collections);
        this.setState({ collectionOptions: collectionsData });
        this.setState({ excludeCollectionOptions: this.initExcludeCollectionOptions(collectionsData)});
        this.setState({ selectedCollection: collectionsData[0].id + '' });
        this.loadTags();
      }).catch(err => {
        this.setState({ hasLoaded: true });
        this.setState({ errorLoading: true });
      });
    } else if (this.props.type === 'existing') {
      getFetch('/app/api/sorts/?id='+this.props.id, {})
        .then((res:any) => res.json()
        ).then(data => {
          getFetch('/app/api/shopify/all-collections', {})
            .then((res:any) => res.json()
            ).then(dataTwo => {
              this.setState({ locationOptions: this.initLocationOptions(dataTwo.locations)});
              let collectionsData = this.prepareCollectionsData(dataTwo.collections);
              this.setState({ collectionOptions: collectionsData });
              let excludeCollectionOptions = this.initExcludeCollectionOptions(collectionsData);
              this.setState({ excludeCollectionOptions: excludeCollectionOptions});
              this.setState({ selectedCollection: data.shopify_collection_id + '' });
              this.setState({ selectedSortCriteria: data.sort_criteria});
              this.setOrderOptions(data.sort_criteria);
              this.setState({ selectedSortOrder: data.sort_order});
              this.setState({ pushOOSToBack: data.push_out_of_stock_to_back});
              this.setState({ pushOOSIgnoreContinue: data.push_oos_ignore_continue});
              this.setState({ pushOOSIgnoreUntracked: data.push_oos_ignore_untracked});
              this.setState({ selectedTargetType: data.target_type });
              if (data.push_to_front_tags) {
                this.setState({pushFrontTags: data.push_to_front_tags.split(',')});
              }
              if (data.push_to_back_tags) {
                this.setState({pushBackTags: data.push_to_back_tags.split(',')});
              }
              if (dataTwo.locations.length <= 1) {
                this.setState({ selectedLocationTarget: 'all'});
              } else {
                this.setState({ selectedLocationTarget: data.inventory_locations_target });
              }
              let excludedCollectionIds = [];
              if (data.exclude_shopify_collection_ids !== '') {
                excludedCollectionIds = data.exclude_shopify_collection_ids.split(',');
                excludedCollectionIds = excludedCollectionIds.map((i:string) => {
                  return parseInt(i);
                });
                excludedCollectionIds = excludedCollectionIds.filter(
                  (excludedCollectionId:number) => {
                    for (let excludeCollectionOption of excludeCollectionOptions) {
                      if (excludeCollectionOption.value === excludedCollectionId) {
                        return true;
                      }
                    }
                    return false; 
                  });
              }
              this.setState({excludedCollectionIds: excludedCollectionIds});

              let selectedLocationIds = [];
              if (data.inventory_location_ids !== '') {
                selectedLocationIds = data.inventory_location_ids.split(',');
                selectedLocationIds = selectedLocationIds.map((i:string)=>{
                  return parseInt(i);
                });
                // check if selected location still exists
                selectedLocationIds = selectedLocationIds.filter((selectedLocationId:number) => {
                  for (let locationOption of this.state.locationOptions) {
                    if (locationOption.value === selectedLocationId) {
                      return true;
                    }
                  }
                  return false;
                });
              }
              // check if location still exists from data
              this.setState({ selectedLocationIds: selectedLocationIds });
              this.loadTags();
            }).catch(err => {
              this.setState({ hasLoaded: true });
              this.setState({ errorLoading: true });
            });
        });
    }
  }

  loadTags = () => {
    let tagsKey = 'tags/' + this.props.shopOrigin;
    let supportsLocalStorage = true;
    let data = null;
    try {
      data = localStorage.getItem(tagsKey);
    } catch (e) {
      supportsLocalStorage = false;
    }
    if (data === null || !supportsLocalStorage) {
      getFetch('/app/api/shopify/all-tags', {})
        .then((res:any) => res.json()
        ).then(data => {
          if (supportsLocalStorage) {
            localStorage.setItem(tagsKey, JSON.stringify(data));
          }
          let availableTags = data.tags;
          availableTags.sort();
          this.setState({ availableTags: this.convertStringArrayToValueLabelObjectArray(availableTags) });
          this.setState({ hasLoaded: true });
        }).catch(err => {
          this.setState({ hasLoaded: true });
          this.setState({ errorLoading: true });
        });
    } else {
        let data = JSON.parse(localStorage.getItem(tagsKey)!);
        let availableTags = data.tags;
        availableTags.sort();
        this.setState({ availableTags: this.convertStringArrayToValueLabelObjectArray(availableTags) });
        this.setState({ hasLoaded: true });
      }
  }

  refreshPage = () => {
    let supportsLocalStorage = true;
    try {
      localStorage.getItem('test');
    } catch (e) {
      supportsLocalStorage = false;
    }
    if (supportsLocalStorage) {
      let tagsKey = 'tags/' + this.props.shopOrigin;
      localStorage.removeItem(tagsKey);
    }
    window.location.reload(false);
  }

  setOrderOptions = (value:string) => {
    let sortOrderOptions = [{
      label: 'Descending (Latest to Oldest)',
      value: 'descending',
      key: 'descending'
    }, {
      label: 'Ascending (Oldest to Latest)',
      value: 'ascending',
      key: 'ascending'
    }];
    if (value === 'alphabetical') {
      sortOrderOptions = [{
        label: 'Descending (Product Title Z to A)',
        value: 'descending',
        key: 'descending'
      }, {
        label: 'Ascending (Product Title A to Z)',
        value: 'ascending',
        key: 'ascending'
      }];
    } else if (value === 'discount_percentage' || value === 'discount_amount' || value === 'price' || value === 'total_inventory') {
      sortOrderOptions = [{
        label: 'Descending (Highest to Lowest)',
        value: 'descending',
        key: 'descending'
      }, {
        label: 'Ascending (Lowest to Highest)',
        value: 'ascending',
        key: 'ascending'
      }];
    }
    this.setState({sortOrderOptions: sortOrderOptions});
  }

  initExcludeCollectionOptions = (collections: Array<any>) => {
    let collectionOptions = [];
    for (let collection of collections) {
      collectionOptions.push({
        label: collection.title,
        value: collection.id
      });
    }
    return collectionOptions;
  }


  initLocationOptions = (locations: Array<any>) => {
    let locationOptions = [];
    for (let location of locations) {
      locationOptions.push({
        label: location.name,
        value: location.id
      })
    }
    return locationOptions;
  }

  save = () => {
    this.setState({isSaving:true});
    let collectionType;
    for (let collectionOption of this.state.collectionOptions) {
      if (collectionOption.value === this.state.selectedCollection) {
        collectionType = collectionOption.collection_type;
        break;
      }
    }

    let body:any = {
      collection_id: this.state.selectedCollection,
      collection_type: collectionType,
      target_type: this.state.selectedTargetType,
      sort_criteria: this.state.selectedSortCriteria,
      sort_order: this.state.selectedSortOrder,
      push_out_of_stock_to_back: this.state.pushOOSToBack,
      push_to_front_tags: this.state.pushFrontTags.join(','),
      push_to_back_tags: this.state.pushBackTags.join(','),
      push_oos_ignore_continue: this.state.pushOOSIgnoreContinue,
      push_oos_ignore_untracked: this.state.pushOOSIgnoreUntracked
    };

    let selectedLocationTarget = this.state.selectedLocationTarget;
    if (this.state.locationOptions.length <= 1) {
      selectedLocationTarget = 'all';
    }

    body.inventory_locations_target = selectedLocationTarget;
    body.inventory_location_ids = this.state.selectedLocationIds.join(',');

    body.exclude_shopify_collection_ids = this.state.excludedCollectionIds.join(',');

    if (this.props.type === 'new') {
      postFetch('/app/api/sorts/create/', body).then(
        (res) => { 
          this.setState({redirectToListing: true});
        }
      ).catch( 
        (err) => console.log(err)
      );
    } else if (this.props.type === 'existing') {
      body.sort_id = this.props.id;
      putFetch('/app/api/sorts/update/', body).then(
        (res) => { 
          this.setState({redirectToListing: true});
        }
      ).catch( 
        (err) => console.log(err)
      );
    }
  }

  convertStringArrayToValueLabelObjectArray(ss: Array<string>) {
    let oa = [];
    for (let s of ss) {
      oa.push({
        value: s, label: s
      });
    }
    return oa;
  }

  prepareCollectionsData = (data: any) => {
    let collectionsData = data; 
    for (let collectionData of collectionsData) {
      collectionData.label = collectionData.title;
      collectionData.value = collectionData.id + '';
      collectionData.key = collectionData.id + '';
    }
    collectionsData.sort(function(a:any, b:any) {
      if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
      }
      if (b.label.toLowerCase() > a.label.toLowerCase()) {
          return -1;
      }
      return 0;
    });
    return collectionsData;
  }
  
  handleCollectionSelectChange = (value: string) => {
    this.setState({selectedCollection: value}); 
  }

  handleTargetTypeChange = (value: string) => {
    this.setState({selectedTargetType: value}); 
  }

  handleSortCriteriaSelectChange = (value: string) => {
    this.setState({selectedSortCriteria: value}); 
    this.setOrderOptions(value);
  }
  
  handleSortOrderSelectChange = (value: string) => {
    this.setState({selectedSortOrder: value}); 
  }

  handleOOSToggle = (val:boolean) => {
    this.setState({pushOOSToBack: val});
  }

  handlePushOOSIgnoreContinue = (val: boolean) => {
    this.setState({pushOOSIgnoreContinue: val})
  }

  handlePushOOSIgnoreUntracked = (val: boolean) => {
    this.setState({pushOOSIgnoreUntracked: val})
  }

  handleLocationTargetSelectChange = (value: string) => {
    this.setState({selectedLocationTarget: value}); 
  }

  selectedLocationIdChange = (selectedLocations: Array<any>) => {
    this.setState({selectedLocationIds: selectedLocations});
  }

  selectedExcludedCollectionIdChange = (selectedCollections: Array<any>) => {
    this.setState({excludedCollectionIds: selectedCollections});
  }

  pushFrontTagsChange = (selectedTags: Array<string>) => {
    this.setState({pushFrontTags:selectedTags});
  }

  pushBackTagsChange = (selectedTags: Array<string>) => {
    this.setState({pushBackTags:selectedTags});
  }

  render() {
    if (this.state.redirectToListing === true) {
      return <Redirect to='/collection-listing?tabindex=1' />
    }
    return (
        <Page
          breadcrumbs={[{content: 'Manage Sorts', onAction:()=>this.setState({redirectToListing: true})}]}>
          {!this.state.hasLoaded && (<Loading />)}
          <DisplayText size="large">{this.props.type === 'new' ? "Create New Sort" : "Edit Sort"}</DisplayText>
          {this.state.showBanner && this.props.type === 'existing' && (<Banner
            title="Modifying a sort"
            status="info"
            onDismiss={() => {this.setState({showBanner: false})}}>
            <p>After you save the modified sort, the app will trigger to sort your products in the specified collection according to your newly chosen criteria. It may take a few minutes before the sorting starts.</p>
          </Banner>)}
          {this.state.showBanner && this.props.type === 'new' && (<Banner
            title="How this works"
            status="info"
            onDismiss={() => {this.setState({showBanner: false})}}>
            <p>After you create a sort on an existing collection, the app will change the order of that collection to manual and sort the items according to the options you have chosen.</p>
          </Banner>)}
          {!this.state.hasLoaded && (
            <div className="loader">
              <Layout><Spinner size="large" /></Layout>
              <Layout><DisplayText size="small">Loading selection lists... This might take a couple of minutes if you have many collections or products. Thank you for your patience.</DisplayText></Layout>
            </div>
          )}          
          {this.state.hasLoaded && (
              <React.Fragment>
              <Card sectioned title="Sort options">
                <div className="indent-section">
                <FormLayout>
                  <Select
                    label="Target"
                    options={this.state.targetTypeOptions}
                    onChange={this.handleTargetTypeChange}
                    value={this.state.selectedTargetType}
                  />
                  {this.state.selectedTargetType === 'single' &&
                    <Select
                      label="Collection to sort"
                      options={this.state.collectionOptions}
                      onChange={this.handleCollectionSelectChange}
                      value={this.state.selectedCollection}
                    />
                  }
                  {this.state.selectedTargetType === 'all' && 
                  <div>
                    <p>If you choose to sort all collections with this criteria, the app will first trigger a sort after you update, and then auto-sort at least once a day. If you have sorts on other collections, they will be excluded from this.<br /><br /></p>
                    <AutocompleteTags requiresTypingToFilter={false} errorLoading={this.state.errorLoading} 
                      helpText="Type more to filter more if it is hard to select / deselect"
                      listTitle='Exclude collections' 
                      selected={this.state.excludedCollectionIds} 
                      onChange={this.selectedExcludedCollectionIdChange} 
                      options={this.state.excludeCollectionOptions} 
                      placeholderText='Type to start searching'>
                        Choose collections to exclude (if any)
                    </AutocompleteTags>
                  </div>}
                </FormLayout>
                </div>
              </Card>
              <Layout>
                <Layout.Section oneHalf>
                  <Card sectioned>
                  <FormLayout>
                    <div className="text-center"><img className="illustrate" src={sortImage} /></div>
                    <p className="text-center"><strong>How to Sort Collections</strong></p>
                    <p className="text-center">Whenever you update your products or collections, the app will do the sorting automatically. The process of sorting may take a few minutes to take effect.</p>
                    <p className="text-center">
                      1. Select a <strong>Primary Sort</strong> order for your selected Collection to be sorted by first.
                    </p>
                    <p className="text-center">
                      2. Next, select products, if any, to be push to the top or bottom of your order. 
                    </p>
                    <p className="text-center">
                      3. Finally, select any inventory options to be applied. 
                    </p>
                  </FormLayout>
                </Card>
                </Layout.Section>
                <Layout.Section oneHalf>
                  <Card sectioned>
                    <FormLayout>
                      <div className="text-center"><img className="illustrate" src={selectionListImage} /></div>
                      <p className="text-center"><strong>Refresh Selection Lists</strong></p>
                      <p className="text-center">Click on the button below to refresh your Selection List of Tags if you are unable to find them in the search fields below.</p>
                      <p className="text-center"><Button onClick={this.refreshPage}>Refresh Selection Lists</Button></p>
                      <p className="text-center subdued">In order to speed up loading time, your store’s selection lists are cached.</p>
                      <p className="text-center subdued">If your store has a lot of collections and products, the refresh time may take a few minutes.</p>
                    </FormLayout>
                  </Card>
                </Layout.Section>
              </Layout>
              
              <Card sectioned  title="Primary Sort">
                <div className="indent-section">
                <FormLayout>
                  <Select
                    label="Sort Criteria"
                    options={this.state.sortCriteriaOptions}
                    onChange={this.handleSortCriteriaSelectChange}
                    value={this.state.selectedSortCriteria}
                  />
                  {this.state.selectedSortCriteria !== 'manual' && this.state.selectedSortCriteria !== 'random' && (
                    <Select
                      label="Sort Order"
                      options={this.state.sortOrderOptions}
                      onChange={this.handleSortOrderSelectChange}
                      value={this.state.selectedSortOrder}
                    />
                  )}
                  {this.state.selectedSortCriteria === 'published_at' && (
                    <p>If you have chosen publish date as a sort criteria, and there are products in the collection with publish dates set in the future, they will be grouped together in the front or at the end depending on what you have chosen for your sort order. Within this group, they might not be sorted in order for the time being. As and when these products are publish as scheduled, the collection will be sorted automatically to display them in the correct order.</p>
                  )}
                </FormLayout>
                </div>
              </Card>
              
              <Card sectioned title="Tag Options">
                <div className="indent-section">
                <FormLayout>
                  <AutocompleteTags requiresTypingToFilter={true} errorLoading={this.state.errorLoading} helpText="A list to select will appear after you type 2 or more letters. Type more to filter more if it is hard to select / deselect" listTitle='Tags to include' selected={this.state.pushFrontTags} onChange={this.pushFrontTagsChange} options={this.state.availableTags} placeholderText='Type to start searching'>Push product to the <strong>TOP</strong> if any one of its tags <strong>EQUALS</strong> to the following:</AutocompleteTags>
                  <AutocompleteTags requiresTypingToFilter={true} errorLoading={this.state.errorLoading} helpText="A list to select will appear after you type 2 or more letters. Type more to filter more if it is hard to select / deselect" listTitle='Tags to include' selected={this.state.pushBackTags} onChange={this.pushBackTagsChange} options={this.state.availableTags} placeholderText='Type to start searching'>Push product to the <strong>END</strong> if any one of its tags <strong>EQUALS</strong> to the following:</AutocompleteTags>
                </FormLayout>
                </div>
              </Card>
              

              <Card sectioned title="Inventory Options">
                <div className="indent-section">
                <FormLayout>
                  <Checkbox label="Push Out of Stock products to the end" onChange={this.handleOOSToggle} checked={this.state.pushOOSToBack}></Checkbox>
                  {this.state.pushOOSToBack &&
                      <Checkbox label="Do not push back products with policy to continue selling even when out of stock" onChange={this.handlePushOOSIgnoreContinue} checked={this.state.pushOOSIgnoreContinue}></Checkbox>
                  }
                  {this.state.pushOOSToBack &&
                      <Checkbox label="Do not push back products which are untracked by Shopify"
                            onChange={this.handlePushOOSIgnoreUntracked}
                            checked={this.state.pushOOSIgnoreUntracked}></Checkbox>
                  }
                  {this.state.locationOptions.length > 1 && this.state.pushOOSToBack && (
                    <Select
                      label="Inventory Location Target"
                      options={this.state.locationTargetOptions}
                      onChange={this.handleLocationTargetSelectChange}
                      value={this.state.selectedLocationTarget}
                    />
                  )}
                  {this.state.selectedLocationTarget === 'selected_locations' && (
                    <AutocompleteTags requiresTypingToFilter={false} errorLoading={this.state.errorLoading} 
                      helpText="Type more to filter more if it is hard to select / deselect"
                      listTitle='Inventory locations' 
                      selected={this.state.selectedLocationIds} 
                      onChange={this.selectedLocationIdChange} 
                      options={this.state.locationOptions} 
                      placeholderText='Type to start searching'>
                        Choose inventory locations to use as basis
                    </AutocompleteTags>
                  )}
                </FormLayout>
                </div>
              </Card>
              <Card sectioned secondaryFooterActions={[{content: 'Cancel', onAction:()=>this.setState({redirectToListing: true}) }]}
              primaryFooterAction={{content: 'Save & Sort', 
              loading:this.state.isSaving, 
              disabled:this.state.selectedLocationIds.length===0 && this.state.selectedLocationTarget==='selected_locations',
              onAction:this.save}}>
              </Card>
              </React.Fragment>
          )}
        </Page>
    );
  }
}

export default Sort;
