import React, { Component } from 'react';
import './NewAutocreatePage.css';
import '@shopify/polaris/dist/styles.css';
import {AppProvider} from '@shopify/polaris';
import { Provider } from '@shopify/app-bridge-react';
import Autocreate from '../components/Autocreate';
import { getFetch } from '../helpers/request';

interface NewAutocreatePageProps { }

interface NewAutocreatePageState {
  shopOrigin: string,
  apiKey: string
}

class NewAutocreatePage extends Component <NewAutocreatePageProps, NewAutocreatePageState> {

  constructor(props: NewAutocreatePageProps) {
    super(props);
    this.state = { 
      shopOrigin: '',
      apiKey: ''
    };
  }

  componentDidMount() {
    //check if log in
    getFetch('/app/api/shopify/user', {})
    .then((res:any) => res.json()
    ).then(data => { 
      this.setState({ shopOrigin: data.shop_origin })
      this.setState({ apiKey: data.api_key })
    }).catch(err => {

    });
  }
  
  render() {
    const config = {apiKey: this.state.apiKey, shopOrigin: this.state.shopOrigin, host:Buffer.from('admin.shopify.com/store/' + this.state.shopOrigin.split('.')[0]).toString('base64')};
    return (
      this.state.shopOrigin !== '' && this.state.apiKey !== '' && 
      <AppProvider i18n={{}}>
        <Provider config={config}>
          <Autocreate type="new"></Autocreate>
        </Provider>
      </AppProvider>
    );
  }
}

export default NewAutocreatePage;
