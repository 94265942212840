import React, { Component } from 'react';
import '@shopify/polaris/dist/styles.css';
import {  Card, TextStyle, DisplayText, List, Link } from '@shopify/polaris';

interface FaqProps { }

interface FaqState { }

class Faq extends Component <FaqProps, FaqState> {

  constructor(props: FaqProps) {
    super(props);
  }

  componentDidMount() {
    
  }

  render() {
    return (
      <React.Fragment>
      <Card.Section>
        <DisplayText>Support Center</DisplayText>
        <p>If you have any questions about Advanced Collections, we are here to help! You can use our support chat near the bottom right corner of the page, we will reply as soon as we can.</p>
      </Card.Section>
      <Card.Section title="Frequently Asked Questions">
      <TextStyle variation="strong">Advanced Collections<br/><br/></TextStyle>
      <List type="number">
        <TextStyle variation="strong"><List.Item>How do I link my advanced collections to my navigation so that customers can view them?</List.Item></TextStyle>
        <p>To link your advanced collections to your navigation so that your customers can find and view them, you have to update your menu. For detailed instructions, <Link external url="https://help.shopify.com/en/manual/products/collections/make-collections-findable">click on this link</Link><br/><br/></p>
        <TextStyle variation="strong"><List.Item>How often does my advanced collections sync?</List.Item></TextStyle>
        <p>On average, your advanced collection auto-syncs every few hours or so.<br/><br/></p>
        <TextStyle variation="strong"><List.Item>Can I change my advanced collections while they are syncing?</List.Item></TextStyle>
        <p>It is not recommended that you change your advanced collections while they are syncing. However if you did so, you will just need to press the 'Sync' button on the relevant collection to update it to the correct state, if it isn't already.<br/><br/></p>
        <TextStyle variation="strong"><List.Item>What happens if I change the order of my items in my advanced collections?</List.Item></TextStyle>
        <p>When your advanced collections sync, the app analyses the changes needed and remove any irrelevant products before adding any relavant products at the end of the collection. If you have elected to sort the collection manually in your Shopify admin, the order will largely be intact except for the changed products.<br/><br/></p>
      </List>
      <TextStyle variation="strong">Sorting Existing Collections<br/><br/></TextStyle>
      <List type="number">
        <TextStyle variation="strong"><List.Item>Why can't I just use Shopify's default sorting mechanism?</List.Item></TextStyle>
        <p>With Shopify's default sorting mechanisms, sorting a collection from 'Newest to Oldest' uses the creation date rather than the publish or updated date. However the creation date cannot be edited and there is no mechanism for the user to decide which products are truly new.<br/><br/></p>
        <TextStyle variation="strong"><List.Item>Why sort collections by publish or updated date?</List.Item></TextStyle>
        <p>The main reason for using either publish or updated date to sort rather than created date is giving the user more control. For example, a shop may take new photos and add new content to a product listing with SKUs used in the past. If we are using Shopify's 'Newest to Oldest' sorting mechanism, and we want to get the product back up at the top of a 'New Arrivals' collection, the only way is to delete the old product and re-add it. However, this is cumbersome and might have implications for SEO. Using the app, you can set the sort to use the 'updated date' to achieve this purpose.<br/><br/></p>
        <TextStyle variation="strong"><List.Item>How does the sorting work?</List.Item></TextStyle>
        <p>When you first create a sort with the Advanced Collections app, it will change the collection you specify to 'Manual Sorting' and start the sorting process according to the criteria you choose. Thereafter, whenever there are updates to your products e.g. a change in publish date, which affects the collections you have specified to sort, the app will do a sort automatically.<br/><br/></p>
        <TextStyle variation="strong"><List.Item>How long does it take for the sorting to take place?</List.Item></TextStyle>
        <p>It typically takes 5 minutes or less to sort.<br/><br/></p>
        <TextStyle variation="strong"><List.Item>Can I use the sorting in conjunction with my advanced collections?</List.Item></TextStyle>
        <p>Yes you can. After creating your advanced collections, you can specify that collection via a dropdown when you create or modify a sort.<br/><br/></p>
      </List>
      </Card.Section>
      <Card.Section>
        <TextStyle variation="strong">Auto-creating Collections<br/><br/></TextStyle>
        <List type="number">
          <TextStyle variation="strong"><List.Item>How does this work?</List.Item></TextStyle>
          <p>After you have created an auto-create mechanism, the app will create automated collections based on the criteria you have chosen, with one condition where tag equals to the specified tag. Whenever you add / update a product with a new tag that matches the criteria, the app will detect it and create an automated collection based on the new tag.<br/><br/></p>
          <TextStyle variation="strong"><List.Item>Will the auto-create mechanism automatically delete collections which tags are no longer in use?</List.Item></TextStyle>
          <p>For the time being, the app will not automatically delete collections with unused tags. You will have to do it manually.<br/><br/></p>
          <TextStyle variation="strong"><List.Item>I am deleting an auto-create together with its created collections. How will my existing collections be affected?</List.Item></TextStyle>
          <p>Only the collections which are created by that particular auto-create mechanism will be deleted. Your other collections will not be affected.<br/><br/></p>
        </List>
      </Card.Section>
    </React.Fragment>
    );
  }
}

export default Faq;
