import React, { Component } from 'react';
import './Autocreate.css';
import '@shopify/polaris/dist/styles.css';
import { Page, Card, Select, FormLayout, DisplayText, TextField, Banner, Layout, Spinner } from '@shopify/polaris';
import { Loading } from '@shopify/app-bridge-react';
import { postFetch } from '../helpers/request';
import { Redirect } from 'react-router';
import TagsInput from './TagsInput';

interface AutocreateProps {
  type: string,
  id?: string
}

interface AutocreateState {
  tagPrefix: string,
  titleTagSplitString: string,
  selectedAutocreateType: string,
  selectedTitleTagSplitIndex: string,
  titleTagSplitIndexOptions: Array<any>,
  errorLoading: boolean,
  hasLoaded: boolean,
  isSaving: boolean,
  redirectToListing: boolean,
  showBanner:boolean,
  tagPrefixTouched:boolean,
  titleTagSplitStringTouched:boolean,
  startsWithTags: any[],
  endsWithTags: any[],
  containsTags: any[],
  selectedCheckExistingCollectionsType: string,
  selectedTitleType: string
}

class Autocreate extends Component <AutocreateProps, AutocreateState> {

  constructor(props: AutocreateProps) {
    super(props);
    this.state = { 
      tagPrefixTouched:false,
      titleTagSplitStringTouched:false,
      selectedAutocreateType: 'tag',
      tagPrefix:'',
      titleTagSplitString: '',
      selectedTitleTagSplitIndex: '2',
      titleTagSplitIndexOptions:  [{
        label: '1st',
        value: '0',
        key: '0',
      }, {
        label: '2nd',
        value: '1',
        key: '1'
      }, {
        label: '3rd',
        value: '2',
        key: '2'
      }, {
        label: '4th',
        value: '3',
        key: '3'
      }, {
        label: '5th',
        value: '4',
        key: '4'
      }],
      isSaving: false,
      hasLoaded: false,
      errorLoading: false,
      redirectToListing: false,
      showBanner: true,
      startsWithTags: [],
      endsWithTags: [],
      containsTags: [],
      selectedCheckExistingCollectionsType: 'none',
      selectedTitleType: 'default'
    };
  }
  

  componentDidMount() {
    if (this.props.type === 'new') {
      this.setState({ hasLoaded: true });
    }
  }

  handleTagPrefixChange = (value:string) => {
    this.setState({tagPrefixTouched: true});
    this.setState({tagPrefix: value});
  };

  handletitleTagSplitStringChange = (value:string) => {
    this.setState({titleTagSplitStringTouched: true});
    this.setState({titleTagSplitString: value});
  }

  save = () => {
    this.setState({isSaving:true});
    

    let body:any = {
      tags_starts_with: this.state.startsWithTags.join(','),
      tags_ends_with: this.state.endsWithTags.join(','),
      tags_contains: this.state.containsTags.join(','),
      title_tag_split_string: this.state.titleTagSplitString,
      title_tag_split_index: this.state.selectedTitleTagSplitIndex,
      title_type: this.state.selectedTitleType,
      check_existing_collections_type: this.state.selectedCheckExistingCollectionsType
    };

    if (this.props.type === 'new') {
      postFetch('/app/api/autocreates/create/', body).then(
        (res) => { 
          this.setState({redirectToListing: true});
        }
      ).catch( 
        (err) => console.log(err)
      );
    }
  }

  handleTitleTypeChange = (value: string) => {
    this.setState({selectedTitleType: value}); 
  }

  handleAutocreateTypeChange = (value: string) => {
    this.setState({selectedAutocreateType: value});
  }
  
  handleTitleTagSplitIndexChange = (value: string) => {
    this.setState({selectedTitleTagSplitIndex: value}); 
  }

  handleStartsWithTagsChange = (startsWithTags: Array<string>) => {
    this.setState({startsWithTags: startsWithTags});
  }

  handleEndsWithTagsChange = (endsWithTags: Array<string>) => {
    this.setState({endsWithTags: endsWithTags});
  } 

  handleContainsTagsChange = (containsTags: Array<string>) => {
    this.setState({containsTags: containsTags});
  }

  handleCheckExistingCollectionsTypeChange = (value:string) => {
    this.setState({selectedCheckExistingCollectionsType: value});
  }

  render() {
    let checkExistingCollectionsOptions = [
      {label:'No need to check existing collections', value:'none', key: 'none'},
      {label:'Do not create collection if there are existing collections with only one condition, which is equivalent', value:'singular', key: 'singular'},
      {label:'Do not create collection if there are existing collections with any number of conditions, one of which is relevant', value:'any', key: 'any'},
    ];

    let titleTypeOptions = [
      {label:'Use tag as collection title', value:'default', key: 'default'},
      {label:'Use a segment of the tag as collection title', value:'split', key: 'split'},
    ]

    let autocreateTypeOptions = [
      {label:'Based on Product Tag', value:'tag', key: 'tag'},
    ]

    if (this.state.redirectToListing === true) {
      return <Redirect to='/collection-listing?tabindex=2' />
    }
    return (
        <Page
          breadcrumbs={[{content: 'Manage Auto-Creates', onAction:()=>this.setState({redirectToListing: true})}]}>
          {!this.state.hasLoaded && (<Loading />)}
          <DisplayText size="large">{this.props.type === 'new' ? "Create New Auto-Create" : "Edit Auto-Create"}</DisplayText>
          {this.state.showBanner && this.props.type === 'existing' && (<Banner
            title="Modifying a Autocreate"
            status="info"
            onDismiss={() => {this.setState({showBanner: false})}}>
            <p>After you save the modified Autocreate, the app will trigger to Autocreate your products in the specified collection according to your newly chosen criteria. It may take a few minutes before the Autocreateing starts.</p>
          </Banner>)}
          {this.state.showBanner && this.props.type === 'new' && (<Banner
            title="How this works"
            status="info"
            onDismiss={() => {this.setState({showBanner: false})}}>
            <p>After you save an auto-create, the app will loop through all product tags to create automated collections (with one condition where tag equals to the specific tag) if the criteria you have chosen are met. As and when product tags are added (through adding / updating of products), relevant collections will be created automatically.</p>
          </Banner>)}
          {!this.state.hasLoaded && (
            <div className="loader">
              <Layout><Spinner size="large" /></Layout>
              <Layout><DisplayText size="small">Loading... This might take a couple of minutes if you have many collections.<br />Thank you for your patience.</DisplayText></Layout>
            </div>
          )}          
          {this.state.hasLoaded && (
            <Card sectioned secondaryFooterActions={[{content: 'Cancel', onAction:()=>this.setState({redirectToListing: true}) }]}
              primaryFooterAction={{content: 'Save & Start Auto-Create', 
              loading:this.state.isSaving, 
              disabled:(this.state.startsWithTags.length === 0 && this.state.endsWithTags.length === 0 && this.state.containsTags.length === 0)  || (this.state.selectedTitleType === 'split' && this.state.titleTagSplitString.length === 0),
              onAction:this.save}}>
              <Card.Section title="Autocreate options">
              <FormLayout>
              <Select
                  label="Auto-create Type"
                  options={autocreateTypeOptions}
                  onChange={this.handleAutocreateTypeChange}
                  value={this.state.selectedAutocreateType}
                />
              <TagsInput selected={this.state.startsWithTags} onChange={this.handleStartsWithTagsChange} placeholderText='Type and press enter'>Auto-create collection for the tag if it <strong>STARTS</strong> with the following</TagsInput>
              <TagsInput selected={this.state.endsWithTags} onChange={this.handleEndsWithTagsChange} placeholderText='Type and press enter'>Auto-create collection for the tag if it <strong>ENDS</strong> with the following:</TagsInput>
              <TagsInput selected={this.state.containsTags} onChange={this.handleContainsTagsChange} placeholderText='Type and press enter'>Auto-create collection for the tag if it <strong>CONTAINS</strong> the following:</TagsInput>

              </FormLayout>
              </Card.Section>
              <Card.Section title="Collection title options">
              <FormLayout>
              <Select
                label="Title Type"
                options={titleTypeOptions}
                onChange={this.handleTitleTypeChange}
                value={this.state.selectedTitleType}
              />
              {this.state.selectedTitleType === 'split' &&
                  <TextField
                      label="Tag Separator (required)"
                      value={this.state.titleTagSplitString}
                      onChange={this.handletitleTagSplitStringChange}
                      error={this.state.titleTagSplitStringTouched && this.state.titleTagSplitString.length===0 ? "Tag separator is required" : false}
                      helpText="To determine the title, we need to understand the format of your product tag. For example, if it is 'owner-1234-Owner Name', you would want to enter '-' here. If it is 'owner|1234|Owner Name', then you should enter '|'."
                  />
              }
              {this.state.selectedTitleType === 'split' &&
                <Select
                  label="Title Tag Split Index"
                  options={this.state.titleTagSplitIndexOptions}
                  onChange={this.handleTitleTagSplitIndexChange}
                  value={this.state.selectedTitleTagSplitIndex}
                  helpText="Finally, you can choose which part of the tag you want to use as the collection title. For example, if the tag is 'owner-1234-Owner Name' and you want 'Owner Name' to be the title of the collection, choose '3rd' here. If the part does not exist, the collection will not be created."
                />
              }
              </FormLayout>
              </Card.Section>
              <Card.Section title="Existing collections options">
                <Select
                  label="Check Existing Collections"
                  options={checkExistingCollectionsOptions}
                  onChange={this.handleCheckExistingCollectionsTypeChange}
                  value={this.state.selectedCheckExistingCollectionsType}
                  helpText="This is useful if you already have existing collections with the associated conditions, and you do not want to have duplicates. Otherwise, if you are starting from scratch, you can select 'No need to check...'"
                />
              </Card.Section>
            </Card>
          )}
        </Page>
    );
  }
}

export default Autocreate;
