import React, { Component } from 'react';
import './TagsInput.css';
import '@shopify/polaris/dist/styles.css';
import { TextField, TextContainer, Stack, Tag, TextStyle, Button } from '@shopify/polaris';

interface TagsInputProps {
    placeholderText:string,
    onChange: Function,
    selected: Array<any>,
    disabled?: boolean
    
}

interface TagsInputState {
    selected: Array<any>,
    inputText: string,
}

class TagsInput extends Component <TagsInputProps, TagsInputState> {
    
    constructor(props: TagsInputProps) {
        super(props);
        this.state = {
            selected: this.props.selected,
            inputText: '',
        };
    }

    render() {
      return (
        <div>
          <Stack>
            <TextStyle>{this.props.children}</TextStyle>
          </Stack>
          <TextContainer>
            <Stack>{this.renderTags()}</Stack>
          </TextContainer>
          <br />
          <Stack>
            <div onKeyDown={this.handleKeyPress}>
              <TextField
                label=''
                onChange={this.handleChange}
                value={this.state.inputText}
                placeholder={this.props.placeholderText}
                disabled={this.props.disabled}
              />
            </div>
            <Button disabled={this.props.disabled} onClick={this.handleClick}>Add</Button>
          </Stack>
        </div>
      );
    }

    onFocus = () => {
        this.setState({

        });
    }

    handleClick = () => {
      let filteredText = this.state.inputText;
      this.addNewTag(filteredText);
    }

    addNewTag = (tag:string) => {
      let newSelected = this.state.selected;
      let filteredTags = tag.split(',');

      for (let filteredTag of filteredTags) {
        if (newSelected.indexOf(filteredTag) === -1 && filteredTag.length>0) {
          newSelected.push(filteredTag);
        }
      }
      this.props.onChange(newSelected);
      this.setState({inputText: ''});
    }

    handleChange = (value: string) => {
      this.setState({inputText: value});
      const lastChar = value.charAt(value.length - 1);
      if (lastChar === ',') {
        this.addNewTag(value.slice(0, -1));
      }
    }

    handleKeyPress = (event:any) => {
      const enterKeyPressed = event.keyCode === 13;
      if (enterKeyPressed) {
        this.addNewTag(this.state.inputText);
      }
    }
  
    removeTag = (tag:string) => {
      let newSelected = this.state.selected;
      newSelected = newSelected.filter(t => t !== tag);
      this.setState({selected: newSelected});
      this.props.onChange(newSelected);
    };
  
    renderTags = () => {
      return this.state.selected.map((tag) => {
          return (
            <Tag disabled={this.props.disabled} key={'option' + tag} onRemove={() => this.removeTag(tag)}>
              {tag}
            </Tag>
          );
      });
    };
  }

  export default TagsInput;