import React, { Component } from 'react';
import './NewSortPage.css';
import '@shopify/polaris/dist/styles.css';
import {AppProvider} from '@shopify/polaris';
import { Provider } from '@shopify/app-bridge-react';
import Sort from '../components/Sort';
import { getFetch } from '../helpers/request';

interface NewSortPageProps { }

interface NewSortPageState {
  shopOrigin: string,
  apiKey: string
}

class NewSortPage extends Component <NewSortPageProps, NewSortPageState> {

  constructor(props: NewSortPageProps) {
    super(props);
    this.state = { 
      shopOrigin: '',
      apiKey: ''
    };
  }

  componentDidMount() {
    //check if log in
    getFetch('/app/api/shopify/user', {})
    .then((res:any) => res.json()
    ).then(data => { 
      this.setState({ shopOrigin: data.shop_origin })
      this.setState({ apiKey: data.api_key })
    }).catch(err => {

    });
  }
  
  render() {
    const config = {apiKey: this.state.apiKey, shopOrigin: this.state.shopOrigin, host:Buffer.from('admin.shopify.com/store/' + this.state.shopOrigin.split('.')[0]).toString('base64')};
    return (
      this.state.shopOrigin !== '' && this.state.apiKey !== '' && 
      <AppProvider i18n={{}}>
        <Provider config={config}>
          <Sort shopOrigin={this.state.shopOrigin} type="new"></Sort>
        </Provider>
      </AppProvider>
    );
  }
}

export default NewSortPage;
