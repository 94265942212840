import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import './CollectionListingPage.css';
import '@shopify/polaris/dist/styles.css';
import {AppProvider, Page} from '@shopify/polaris';
import CollectionListing from '../components/CollectionListing';
import { Provider } from '@shopify/app-bridge-react';
import { getFetch } from '../helpers/request';

interface CollectionListingPageProps {

}

interface CollectionListingPageState {
  shopOrigin: string,
  apiKey: string
}

class CollectionListingPage extends Component <CollectionListingPageProps, CollectionListingPageState> {

  static contextTypes = {
		polaris: PropTypes.object
  };
  
  constructor(props: CollectionListingPageProps) {
    super(props);
    this.state = { 
      shopOrigin: '',
      apiKey: ''
    };
  }

  componentDidMount() {
    getFetch('/app/api/shopify/user', {})
    .then((res:any) => res.json()
    ).then((data) => {
      this.setState({ shopOrigin: data.shop_origin });
      this.setState({ apiKey: data.api_key });
    }).catch(err => {

    });
  }
  
  render() {
    const config = {apiKey: this.state.apiKey, shopOrigin: this.state.shopOrigin, host:Buffer.from('admin.shopify.com/store/' + this.state.shopOrigin.split('.')[0]).toString('base64')};
    return (
      this.state.shopOrigin !== '' && this.state.apiKey !== '' && 
      <AppProvider i18n={{}}>
        <Provider config={config}>
          <Page>
            <CollectionListing></CollectionListing>
          </Page>
        </Provider>
      </AppProvider>
    );
  }
}

export default CollectionListingPage;
