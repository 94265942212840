import React, { Component } from 'react';
import {AppBridgeContext} from '@shopify/app-bridge-react/context';
import { getFetch, postFetch, deleteFetch, patchFetch } from '../helpers/request';
import Toggle from 'react-toggle';
import './CollectionListing.css';
import '@shopify/polaris/dist/styles.css';
import { Card, DataTable, Button, Link, EmptyState, Banner, TextContainer,
  DisplayText, Stack, Spinner, TextStyle, Layout, Badge, Tabs, Modal } from '@shopify/polaris';
import { Redirect } from '@shopify/app-bridge/actions';
import { Loading } from '@shopify/app-bridge-react';
import Faq from './Faq';
import TimeAgo from 'react-timeago';
import emptyStateImage from '../images/product.svg';

interface CollectionListingProps {

}

interface CollectionListingState {
  collections: Array<any>,
  sorts: Array<any>,
  autocreates: Array<any>,
  hasLoaded: Boolean,
  errorLoading: Boolean,
  isRefreshing: Boolean,
  showBanner: Boolean,
  skipRefresh: Boolean,
  isRefreshingSorts: Boolean,
  skipRefreshSorts: Boolean,
  isRefreshingAutocreates: Boolean,
  skipRefreshAutocreates: Boolean,
  selectedTabIndex: number,
  deleteModalActive: boolean,
  deletingSortId: string,
  deletingAutocreateId: string,
  deleteAutocreateModalActive: boolean,
  autocreateModalActive: boolean,
  currentCollectionsCreated: Array<any>,
  currentCollectionsLoading: boolean,
  disableToggle: boolean,
  bulkSortAllExists: boolean,
}

class CollectionListing extends Component <CollectionListingProps, CollectionListingState> {
  
  static contextType = AppBridgeContext;
  refreshInterval: any;
  refreshSortsInterval: any;
  refreshAutocreatesInterval: any;

  constructor(props: CollectionListingProps) {
    super(props);
    this.state = { 
      collections: [],
      sorts: [],
      autocreates: [],
      hasLoaded: false,
      errorLoading: false,
      isRefreshing: false,
      showBanner: true,
      skipRefresh: false,
      selectedTabIndex: 0,
      deleteModalActive: false,
      deletingSortId: '',
      isRefreshingSorts: false,
      skipRefreshSorts: false,
      isRefreshingAutocreates: false,
      skipRefreshAutocreates: false,
      deletingAutocreateId: '',
      deleteAutocreateModalActive: false,
      autocreateModalActive: false,
      currentCollectionsCreated: [],
      currentCollectionsLoading: false,
      disableToggle: false,
      bulkSortAllExists: false
    };
  }

  componentDidMount() {
    getFetch('/app/api/shopify/advanced-collections', {})
    .then((res:any) => res.json()
    ).then(data => { 
      this.setState({ collections: data.collections });
      this.refresh();
      this.refreshInterval = setInterval(() => {
        this.refresh();
      }, 15000);
      getFetch('/app/api/all-sorts', {})
      .then((res:any) => res.json()
      ).then(dataTwo => {
        this.setState({ sorts: dataTwo.sorts })
        this.setState({ bulkSortAllExists: this.checkIfBulkSortAllExists(dataTwo.sorts)});
        this.refreshSortsInterval = setInterval(() => {
          this.refreshSorts();
        }, 15000);
        getFetch('/app/api/all-autocreates', {})
        .then((res:any) => res.json()
        ).then(dataThree => {
          this.setState({ autocreates: dataThree.autocreates })
          this.refreshSortsInterval = setInterval(() => {
            this.refreshAutocreates();
          }, 15000);
          if (window.location.search === '?tabindex=1') {
            this.setState({selectedTabIndex: 1});
          } else if (window.location.search === '?tabindex=0') { 
            this.setState({selectedTabIndex: 0});
          } else if (window.location.search === '?tabindex=2') {
            this.setState({selectedTabIndex: 2});
          } else {
            if (dataTwo.sorts.length > 0 && data.collections.length === 0 && dataThree.autocreates.length === 0) {
              this.setState({selectedTabIndex: 1});
            } else if (dataTwo.sorts.length === 0 && data.collections.length === 0 && dataThree.autocreates.length > 0) {
              this.setState({selectedTabIndex: 2});
            } else if (dataTwo.sorts.length === 0 && data.collections.length > 0 && dataThree.autocreates.length === 0) {
              this.setState({selectedTabIndex: 0});
            }
          }
          this.setState({ hasLoaded: true });
        });
      }).catch(err => {
        this.setState({ hasLoaded: true });
        this.setState({ errorLoading: true });
      });

    }).catch(err => {
      this.setState({ hasLoaded: true });
      this.setState({ errorLoading: true });
    });
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
    clearInterval(this.refreshSortsInterval);
    clearInterval(this.refreshAutocreatesInterval);
  }

  sync = (index:number) => {
    let collections = this.state.collections;
    collections[index].is_syncing = true;
    this.setState({skipRefresh: true});
    this.setState({collections: collections});
    let body = {
      id: collections[index].shopify_id
    };
    postFetch('/app/api/collections/sync/', body).then(
      (res) => { 
        clearInterval(this.refreshInterval);
        this.refreshInterval = setInterval(() => {
          this.refresh();
        }, 15000);
      }
    ).catch( 
      (err) => console.log(err)
    );
  }

  syncAutocreate = (index:number) => {
    let autocreates = this.state.autocreates;
    autocreates[index].is_syncing = true;
    this.setState({skipRefreshAutocreates: true});
    this.setState({autocreates: autocreates});
    let body = {
      id: autocreates[index].id
    };
    postFetch('/app/api/autocreates/sync/', body).then(
      (res) => { 
        clearInterval(this.refreshAutocreatesInterval);
        this.refreshSortsInterval = setInterval(() => {
          this.refreshAutocreates();
        }, 15000);
      }
    ).catch( 
      (err) => console.log(err)
    );
  };

  sort = (index:number) => {
    let sorts = this.state.sorts;
    sorts[index].is_syncing = true;
    this.setState({skipRefreshSorts: true});
    this.setState({sorts: sorts});
    let body = {
      sort_id: sorts[index].sort_id
    };
    postFetch('/app/api/collections/sort/', body).then(
      (res) => { 
        clearInterval(this.refreshSortsInterval);
        this.refreshSortsInterval = setInterval(() => {
          this.refreshSorts();
        }, 15000);
      }
    ).catch( 
      (err) => console.log(err)
    );
  }

  handleModalCancel = () => {
    this.setState({deleteModalActive: false});
    this.setState({deleteAutocreateModalActive: false});
  }

  triggerDeleteSort= (index:number) => {
    let deletingSortId = this.state.sorts[index].sort_id;
    this.setState({deletingSortId: deletingSortId});
    this.setState({deleteModalActive: true});
    this.setState({skipRefreshSorts: true});
  }


  triggerDeleteAutocreate= (index:number) => {
    let deletingAutocreateId = this.state.autocreates[index].id;
    this.setState({deletingAutocreateId: deletingAutocreateId});
    this.setState({deleteAutocreateModalActive: true});
    this.setState({skipRefreshAutocreates: true});
  }

  triggerAutocreateModal = (id:string) => {
    this.setState({currentCollectionsCreated:[]});
    this.setState({currentCollectionsLoading: true})
    this.setState({autocreateModalActive: true})
    getFetch('/app/api/autocreates?id=' + id, {}).then((res:any) => res.json())
      .then(data => { 
        this.setState({currentCollectionsLoading: false})
        let sortedCurrentCollections = data.collections.sort((n1:any, n2:any) => {
            if (n1.title > n2.title) {
                return 1;
            }
            if (n1.title < n2.title) {
                return -1;
            }
            return 0;
        });
        this.setState({currentCollectionsCreated: sortedCurrentCollections});
      }
    ).catch( 
      (err) => console.log(err)
    );
  }

  handleAutocreateModalCancel = () => {
    this.setState({autocreateModalActive: false})
  }

  handleAutocreateToggle = (event:any, index:number, id:number) => {
    const autocreates = this.state.autocreates.map((item, j) => {
      if (j === index) {
        item.enabled = event.target.checked;
        item.disableToggle = true;
        return item;
      } else {
        return item;
      }
    });
    this.setState({autocreates});
    patchFetch('/app/api/autocreates/enable/', { id: id, enabled:event.target.checked }).then(
      (res) => {
        const autocreates = this.state.autocreates.map((item, j) => {
          if (j === index) {
            item.disableToggle = false;
            return item;
          } else {
            return item;
          }
        });
        this.setState({autocreates});
      }
    );
  }


  handleAutocreateModalDelete = () => {
    this.setState({skipRefreshAutocreates: true});
    let body = {
      id: this.state.deletingAutocreateId
    };
    deleteFetch('/app/api/autocreates/delete/', body).then(
      (res) => { 
        let resultingAutocreates = [];
        for (let autocreate of this.state.autocreates) {
          if (autocreate.id !== this.state.deletingAutocreateId) {
            resultingAutocreates.push(autocreate);
          }
        }
        this.setState({autocreates: resultingAutocreates});
        this.setState({deleteAutocreateModalActive: false});
      }
    ).catch( 
      (err) => console.log(err)
    );
  }

  handleAutocreateModalDeleteWithCollections = () => {
    this.setState({skipRefreshAutocreates: true});
    let body = {
      id: this.state.deletingAutocreateId
    };
    deleteFetch('/app/api/autocreates/delete-collections/', body).then(
      (res) => { 
        let resultingAutocreates = [];
        for (let autocreate of this.state.autocreates) {
          if (autocreate.id !== this.state.deletingAutocreateId) {
            resultingAutocreates.push(autocreate);
          }
        }
        this.setState({autocreates: resultingAutocreates});
        this.setState({deleteAutocreateModalActive: false});
      }
    ).catch( 
      (err) => console.log(err)
    );
  }

  handleModalDelete = () => {
    this.setState({skipRefreshSorts: true});
    let body = {
      id: this.state.deletingSortId
    };
    deleteFetch('/app/api/sorts/delete/', body).then(
      (res) => { 
        let resultingSorts = [];
        for (let sort of this.state.sorts) {
          if (sort.sort_id !== this.state.deletingSortId) {
            resultingSorts.push(sort);
          }
        }
        this.setState({sorts: resultingSorts});
        this.setState({ bulkSortAllExists: this.checkIfBulkSortAllExists(resultingSorts)});
        this.setState({deleteModalActive: false});
      }
    ).catch( 
      (err) => console.log(err)
    );
  }

  redirectToShopifyCollection(id:string) {
    const redirect = Redirect.create(this.context);
    redirect.dispatch(Redirect.Action.ADMIN_SECTION, {
      name: Redirect.ResourceType.Collection,
      resource: {
        id: id.toString(),
      },
    });
  }

  refresh() {
    this.setState({ isRefreshing: true });
    getFetch('/app/api/shopify/advanced-collections', {})
    .then((res:any) => res.json()
    ).then(data => { 
      if (!this.state.skipRefresh) {
        this.setState({ collections: data.collections });
      } else {
        this.setState({ skipRefresh: false });
      }
      this.setState({ isRefreshing: false });
    }).catch(err => {
      this.setState({ isRefreshing: false });
    });
  }

  refreshSorts() {
    this.setState({ isRefreshingSorts: true });
    getFetch('/app/api/all-sorts', {})
    .then((res:any) => res.json()
    ).then(data => { 
      if (!this.state.skipRefreshSorts) {
        this.setState({ sorts: data.sorts });
        this.setState({ bulkSortAllExists: this.checkIfBulkSortAllExists(data.sorts)});
      } else {
        this.setState({ skipRefreshSorts: false });
      }
      this.setState({ isRefreshingSorts: false });
    }).catch(err => {
      this.setState({ isRefreshingSorts: false });
    });
  }

  checkIfBulkSortAllExists(sorts:any) {
    for (let sort of sorts) {
      if (sort.target_type === 'all') {
        return true;
      }
    }
    return false;
  }

  refreshAutocreates() {
    this.setState({ isRefreshingAutocreates: true });
    getFetch('/app/api/all-autocreates', {})
    .then((res:any) => res.json()
    ).then(data => { 
      if (!this.state.skipRefreshAutocreates) {
        this.setState({ autocreates: data.autocreates });
      } else {
        this.setState({ skipRefreshAutocreates: false });
      }
      this.setState({ isRefreshingAutocreates: false });
    }).catch(err => {
      this.setState({ isRefreshingAutocreates: false });
    });
  }


  renderExcludedBadges = (index:number) => {
    return this.state.collections[index].excluded_tags.map((tag:string) => {
      return(<Badge key={tag}>{tag}</Badge>)
    });
  }

  renderCollectionBadges = (index:number) => {
    return this.state.collections[index].associated_smart_collections.map((s:any) => {
      return(<Badge key={s} status="success">{s}</Badge>)
    });
  }

  handleTabChange = (selectedTabIndex:number) => {
    this.setState({selectedTabIndex: selectedTabIndex});
  };

  render() {
    const tabs = [
      {
        id: 'collections-listing',
        content: 'Manage Collections',
        panelID: 'collections-panel',
      },
      {
        id: 'collection-sorts',
        content: 'Manage Sorts',
        panelID: 'sorts-panel',
      },
      {
        id: 'auto-creates',
        content: 'Manage Auto-Creates',
        panelID: 'auto-creates-panel',
      },
      {
        id: 'support',
        content: 'Support Center',
        panelID: 'support-panel',
      },
    ];

    const checkExistingCollectionLabels:any = {
      none: 'No need to check',
      singular: 'With single condition equivalent',
      any: 'Wth any conditions equivalent',
    }
    
    let autoCreateRows:any[] = [];
    this.state.autocreates.forEach((autocreate, index) => {
      let indexLabel = '1st';
      if (autocreate.title_tag_split_index === 1) {
        indexLabel = '2nd';
      } else if (autocreate.title_tag_split_index === 2) {
        indexLabel = '3rd';
      } else if (autocreate.title_tag_split_index === 3) {
        indexLabel = '4th';
      } else if (autocreate.title_tag_split_index === 4) {
        indexLabel = '5th';
      }
      let row:any[] = [
        <TextStyle>
          <strong>{autocreate.tags_starts_with ? 'Starts with: ' : ''}</strong>{autocreate.tags_starts_with ? autocreate.tags_starts_with.replace(',', ', ') + '\n': ''}
          <strong>{autocreate.tags_ends_with ? 'Ends with: ' : ''}</strong>{autocreate.tags_ends_with ? autocreate.tags_ends_with.replace(',', ', ') + '\n': ''}
          <strong>{autocreate.tags_contains ? 'Contains: ' : ''}</strong>{autocreate.tags_contains ? autocreate.tags_contains.replace(',', ', ') + '\n': ''}
          {autocreate.title_type === 'split' &&
            <span>
              <strong>Separated by:</strong> {autocreate.title_tag_split_string} {'\n'}
              <strong>Title Index:</strong> {indexLabel + '\n'}
            </span>
           }
           {autocreate.title_type === 'default' &&
            <span>
              <strong>Title:</strong> Use tag {'\n'}
            </span>
           }
           <strong>Check existing:</strong> {checkExistingCollectionLabels[autocreate.check_existing_collections_type]}
        </TextStyle>,
        autocreate.is_syncing ? <Stack><Spinner size="small" /><TextStyle variation="positive">Creating</TextStyle></Stack> : 
        autocreate.last_synced !== null ? <TimeAgo date={ autocreate.last_synced } /> : <TextStyle variation="subdued">Added to server queue {autocreate.last_synced_history !== null ? <p>Last Synced: <TimeAgo date={autocreate.last_synced_history}  /></p> : ''}</TextStyle>,
        <Toggle key={autocreate.id} icons={false} onChange={(event)=> {this.handleAutocreateToggle(event, index, autocreate.id)}} defaultChecked={autocreate.enabled} disabled={autocreate.disableToggle || autocreate.last_synced == null || autocreate.is_syncing}></Toggle>,
        <Stack>
          <Button size="slim" onClick={() => this.syncAutocreate(index)} primary disabled={autocreate.is_syncing || autocreate.last_synced == null || !autocreate.enabled}>Sync</Button>
          <Button size="slim" onClick={() => this.triggerDeleteAutocreate(index)} destructive>Delete</Button>
          {!autocreate.is_syncing && autocreate.last_synced != null && (<div className="edit"><Link monochrome onClick={()=>this.triggerAutocreateModal(autocreate.id)}>View Collections</Link></div>)}
        </Stack>
      ];
      autoCreateRows.push(row);
    }); 

    let sortRows:any[] = [];
    let sortCriteriaMaps:any = {
      created_at: 'Created Date',
      published_at: 'Publish Date',
      updated_at: 'Updated Date',
      alphabetical: 'Alphabetically',
      price: 'By Price',
      discount_percentage: 'By Discount Percentage',
      discount_amount: 'By Discount Amount',
      manual: 'Manually',
      random: 'Randomly',
      total_inventory: 'Total Inventory'
    }
    let ascendingMaps:any = {
      created_at: {
        ascending: 'Oldest to Latest (Ascending)',
        descending: 'Latest to Oldest (Descending)'
      },
      published_at: {
        ascending: 'Oldest to Latest (Ascending)',
        descending: 'Latest to Oldest (Descending)'
      },
      updated_at: {
        ascending: 'Oldest to Latest (Ascending)',
        descending: 'Latest to Oldest (Descending)'
      },
      alphabetical: {
        ascending: 'Product Title A to Z (Ascending)',
        descending: 'Product Title Z to A (Descending)'
      },
      price: {
        ascending: 'Lowest to Highest (Ascending)',
        descending: 'Highest to Lowest (Descending)'
      },
      total_inventory: {
        ascending: 'Lowest to Highest (Ascending)',
        descending: 'Highest to Lowest (Descending)'
      },
      discount_percentage: {
        ascending: 'Lowest to Highest (Ascending)',
        descending: 'Highest to Lowest (Descending)'
      },
      discount_amount: {
        ascending: 'Lowest to Highest (Ascending)',
        descending: 'Highest to Lowest (Descending)'
      }
    }
    this.state.sorts.forEach((sort, index) => {
      let row:any[] = [
        <Link url={"/sorts/"+sort.sort_id}>{sort.target_type === 'all' ? 'All Collections' : sort.collection_title}</Link>,
        <TextStyle>{ sortCriteriaMaps[sort.sort_criteria] }</TextStyle>,
        sort.sort_criteria === 'manual' || sort.sort_criteria === 'random' ? <TextStyle>-</TextStyle> : <TextStyle>{ ascendingMaps[sort.sort_criteria][sort.sort_order] }</TextStyle>,
        sort.is_syncing ? <Stack><Spinner size="small" /><TextStyle variation="positive">Sorting</TextStyle></Stack> : 
      sort.last_synced !== null ? <TimeAgo date={ sort.last_synced } /> : <TextStyle variation="subdued">Added to server queue {sort.last_synced_history !== null ? <p>Last Synced: <TimeAgo date={sort.last_synced_history}  /></p> : ''}</TextStyle>,
        <Stack>
          <Button size="slim" onClick={() => this.sort(index)} primary disabled={sort.is_syncing || sort.last_synced == null}>Sort</Button>
          <Button size="slim" onClick={() => this.triggerDeleteSort(index)} destructive>Delete Sort</Button>
          {sort.target_type === 'single' && !sort.is_syncing && sort.last_synced != null && (<div className="edit"><Link monochrome onClick={()=>this.redirectToShopifyCollection(sort.shopify_collection_id)}>Edit in Admin</Link></div>)}
        </Stack>
      ];
      sortRows.push(row);
    });
    
    let rows:any[] = [];
    this.state.collections.forEach((collection, index) => {
      let row = [
        <Link url={"/collections/"+collection.shopify_id}>{collection.title}</Link>,
        collection.auto_sync ? <TextStyle variation="positive">ON</TextStyle> : <TextStyle variation="subdued">OFF</TextStyle>,
        collection.is_syncing ? <Stack><Spinner size="small" /><TextStyle variation="positive">Syncing</TextStyle></Stack> : 
        collection.last_synced !== null ? <TimeAgo date={ collection.last_synced } /> : <TextStyle variation="subdued">Added to server queue {collection.last_synced_history !== null ? <p>Last Synced: <TimeAgo date={collection.last_synced_history}  /></p> : ''}</TextStyle>,
        <Stack>
          <Button size="slim" onClick={() => this.sync(index)} primary disabled={collection.is_syncing || collection.last_synced == null}>Sync</Button>
          {!collection.is_syncing && collection.last_synced != null && (<div className="edit"><Link monochrome onClick={()=>this.redirectToShopifyCollection(collection.shopify_id)}>Edit in Admin</Link></div>)}
        </Stack>
      ]
      rows.push(row);
    });

    let currentCollectionsRows:any[] = [];
    this.state.currentCollectionsCreated.forEach((collection, index) => {
      let row = [
        collection.title,
        <Link monochrome onClick={()=>this.redirectToShopifyCollection(collection.id)}>Edit in Admin</Link>
      ]
      currentCollectionsRows.push(row)
    });

    return (
      <React.Fragment>
        <Modal
          open={this.state.deleteModalActive}
          onClose={this.handleModalCancel}
          title="Delete Sort"
          primaryAction={{
            content: 'Confirm Delete',
            onAction: this.handleModalDelete,
            destructive: true,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: this.handleModalCancel,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>Please confirm that you want to delete the sorting mechanism for this collection.</p>
            </TextContainer>
          </Modal.Section>
        </Modal>
        
        <Modal
          open={this.state.autocreateModalActive}
          onClose={this.handleAutocreateModalCancel}
          title="View Collections Created" 
          primaryAction={{
            content: 'Okay',
            onAction: this.handleAutocreateModalCancel,
          }}>
          <Modal.Section>
            {this.state.currentCollectionsLoading && (<Layout><Spinner /></Layout>)}
            {!this.state.currentCollectionsLoading && (
              <DataTable
                columnContentTypes={[
                  'text',
                  'text'
                ]}
                headings={[
                  'Collection Title',
                  'Actions'
                ]}
                rows={currentCollectionsRows}
              />
            )}
          </Modal.Section>
        </Modal>


        <Modal
          open={this.state.deleteAutocreateModalActive}
          onClose={this.handleModalCancel}
          title="Delete Autocreate"
          primaryAction={{
            content: 'Delete Auto-create only',
            onAction: this.handleAutocreateModalDelete,
            destructive: true,
          }}
          secondaryActions={[
            {
              content: 'Cancel',
              onAction: this.handleModalCancel,
            },
            {
              content: 'Delete Auto-create and Collections',
              onAction: this.handleAutocreateModalDeleteWithCollections,
              destructive: true,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <p>Please confirm that you want to delete the auto-create mechanism. This action cannot be reversed.</p>
              <p>If you choose to delete the collections created by the auto-create as well, it may take a few minutes to process before it is reflected on the Shopify admin.</p>
            </TextContainer>
          </Modal.Section>
        </Modal>

        {!this.state.hasLoaded && (<Loading />)}
        {!this.state.hasLoaded && (<div><Layout><Spinner size="large" /></Layout></div>)}
        {this.state.hasLoaded && this.state.errorLoading &&
        (
          <Banner
            title="Error loading account"
            status="critical"
          >
            <p>Please ensure that you are logged in on Shopify.</p>
          </Banner>
        )}
        {/*this.state.hasLoaded && this.state.collections.length>0 && this.state.showBanner && (
          <Banner
            title="Managing your advanced collections"
            status="info"
            onDismiss={() => {this.setState({showBanner: false})}}>
            <p>Here you can view the statuses of your advanced collections and sync them manually if they are not already syncing. To delete an advanced collection, you can do so in the Shopify admin interface.</p>
          </Banner>
        )*/}
        {this.state.hasLoaded && !this.state.errorLoading && 
        this.state.collections.length === 0 && this.state.sorts.length === 0 && this.state.autocreates.length === 0 &&
          (
            <EmptyState
              heading="Advanced Collections"
              image={emptyStateImage}>
                <p>Create auto-syncing collections with advanced conditions. Sort your existing collections using advanced criteria. Auto-create collections based on product tags.<br/><br/></p>
                <Button size="large" primary url='/new'>Create an advanced collection</Button>
                <br /><br />
                <Button size="large" primary url='/new-sort'>Sort an existing collection</Button>
                <br /><br />
                <Button size="large" primary url='/new-autocreate'>Auto-create collections</Button>
            </EmptyState>
          )
        }
        {this.state.hasLoaded && !this.state.errorLoading 
        && (this.state.collections.length > 0 || this.state.sorts.length > 0 || this.state.autocreates.length > 0) && (
          <Card>
          <Tabs tabs={tabs} selected={this.state.selectedTabIndex} onSelect={this.handleTabChange}>
          {this.state.selectedTabIndex === 3 && (
              <Faq></Faq>
          )}

          {this.state.selectedTabIndex === 2 && (
              <React.Fragment>
              <Card.Section>
                <Stack>
                  <Stack.Item fill>
                    <Stack>
                      <DisplayText>Manage Auto-Creates</DisplayText>
                      {this.state.isRefreshingSorts && (<div className="main-spinner"><Spinner size="small" /></div>)}
                    </Stack>
                  </Stack.Item>
                  <Stack.Item>
                    <Button url='/new-autocreate' primary>Create Auto-Create</Button>
                  </Stack.Item>
                </Stack>
                <p>Here you can view the statuses of your mechanisms to auto-create collections, and trigger them automatically if they are not already auto-creating. You can also delete an auto-create if it is no longer required.</p>
                </Card.Section>
                <Card.Section>
                {this.state.autocreates.length > 0 && (
                  <DataTable
                    columnContentTypes={[
                      'text',
                      'text',
                      'text',
                      'text',
                    ]}
                    headings={[
                      'Conditions',
                      'Last Synced',
                      'Enabled',
                      'Actions',
                    ]}
                  rows={autoCreateRows} />
                )}
                {this.state.autocreates.length === 0 && (
                  <TextContainer>
                    <p>You don't have any auto-creates yet, click the button below to create one!</p>
                    <Button url='/new-autocreate' primary>Create Auto-Create</Button>
                  </TextContainer>
                )}
              </Card.Section>
              </React.Fragment>
          )}

          {this.state.selectedTabIndex === 1 && (
              <React.Fragment>
              <Card.Section>
                <Stack>
                  <Stack.Item fill>
                    <Stack>
                      <DisplayText>Manage Sorts</DisplayText>
                      {this.state.isRefreshingSorts && (<div className="main-spinner"><Spinner size="small" /></div>)}
                    </Stack>
                  </Stack.Item>
                  <Stack.Item>
                    <Button url='/new-sort' primary>Create Sort</Button>
                  </Stack.Item>
                </Stack>
                <p>Here you can view the statuses of your collection sorts and sort them manually if they are not already sorting. You can also delete a sort if it is no longer required, or edit one by clicking on the collection name.</p>
                </Card.Section>
                <Card.Section>
                {this.state.sorts.length > 0 && (
                  <DataTable
                    columnContentTypes={[
                      'text',
                      'text',
                      'text',
                      'text',
                      'text',
                    ]}
                    headings={[
                      'Collection',
                      'Sort Criteria',
                      'Sort Order',
                      'Last Sorted',
                      'Actions'
                    ]}
                  rows={sortRows} />
                )}
                {this.state.sorts.length === 0 && (
                  <TextContainer>
                    <p>You don't have any sorts yet, click the button below to create one!</p>
                    <Button url='/new-sort' primary>Create Sort</Button>
                  </TextContainer>
                )}
              </Card.Section>
              </React.Fragment>
            )}

            {this.state.selectedTabIndex === 0 && (
              <React.Fragment>
              <Card.Section>
                <Stack>
                  <Stack.Item fill>
                    <Stack>
                      <DisplayText>Manage Advanced Collections</DisplayText>
                      {this.state.isRefreshing && (<div className="main-spinner"><Spinner size="small" /></div>)}
                    </Stack>
                  </Stack.Item>
                  <Stack.Item>
                    <Button url='/new' primary>Create Advanced Collection</Button>
                  </Stack.Item>
                </Stack>
                <p>Here you can view the statuses of your advanced collections and sync them manually if they are not already syncing. To delete an advanced collection, you can do so in the Shopify admin interface.</p>
                <p><br />To link your advanced collections to your navigation so that your customers can find and view them, you have to update your menu. For detailed instructions, <Link external url="https://help.shopify.com/en/manual/products/collections/make-collections-findable">click on this link</Link></p>
                </Card.Section>
                <Card.Section>
                {this.state.collections.length > 0 && (
                  <DataTable
                    columnContentTypes={[
                      'text',
                      'text',
                      'text',
                      'text',
                    ]}
                    headings={[
                      'Title',
                      'Auto Sync',
                      'Last Synced',
                      'Actions'
                    ]}
                    rows={rows} />
                  )}
                  {this.state.collections.length === 0 && (
                    <TextContainer>
                      <p>You don't have any advanced collections yet, click the button below to create one!</p>
                      <Button url='/new' primary>Create Advanced Collection</Button>
                    </TextContainer>
                  )}
              </Card.Section>
              </React.Fragment>
            )}
          </Tabs>
          </Card>
          )}
      </React.Fragment>
    );
  }
}

export default CollectionListing;
