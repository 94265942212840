import React, { Component } from 'react';
import './App.css';
import '@shopify/polaris/dist/styles.css';

import { BrowserRouter as Router, Route } from "react-router-dom";
import CollectionListingPage from "./pages/CollectionListingPage";
import NewCollectionPage from "./pages/NewCollectionPage";
import NewSortPage from "./pages/NewSortPage";
import NewAutocreatePage from "./pages/NewAutocreatePage";
import LoginPage from "./pages/LoginPage";
import CollectionPage from "./pages/CollectionPage";
import SortPage from "./pages/SortPage";
import PrivacyPage from "./pages/PrivacyPage";

interface AppProps {

}

interface AppState {
  shopOrigin: string
}

class App extends Component <AppProps, AppState> {

  constructor(props: AppProps) {
    super(props);
    this.state = { 
      shopOrigin: ''
    };
  }

  componentDidMount() {
  }
  
  render() {
    return (
      <Router>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/collection-listing" component={CollectionListingPage} />
        <Route exact path="/new" component={NewCollectionPage} />
        <Route exact path="/new-sort" component={NewSortPage} />
        <Route exact path="/new-autocreate" component={NewAutocreatePage} />
        <Route path="/collections/:collectionId" component={CollectionPage} />
        <Route path="/sorts/:sortId" component={SortPage} />
        <Route exact path="/privacy" component={PrivacyPage} />
      </Router>
    );
  }
}

export default App;
